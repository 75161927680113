import React, { useEffect, useState, useCallback } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
	Box,
	Button,
	FormControl,
	Select,
	MenuItem,
	TextField,
	Typography,
	debounce,
	Autocomplete
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	overflowY: 'scroll',
	maxHeight: '95vh'
};

interface AddCohortProps {
	setOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	isRefresh: boolean;
}

type AddCohortState = {
	name: string;
	company_id: number;
	scenario_ids: number[];
	subScenario_ids: number[];
	scenarioSequence: number[];
	scenario_passing_score: number;
	min_passed_scenario: number;
	maxUser: number;
};

const AddCohort: React.FC<AddCohortProps> = ({ setOpen, setIsRefresh, isRefresh }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [allScenerio, setAllScenerio] = useState([]);
	const [subScenerio, setSubScenerio] = useState([]);
	const [allCompany, setAllCompany] = useState([]);
	const [addcohort, setAddcohort] = useState<AddCohortState>({
		name: '',
		company_id: 0,
		scenario_ids: [],
		subScenario_ids: [],
		scenarioSequence: [],
		scenario_passing_score: 0,
		min_passed_scenario: 0,
		maxUser: 0
	});
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		setIsLoading(true);
		getAllScenerio();
		getAllCompany();
	}, []);

	useEffect(() => {
		if (addcohort.scenario_ids.length > 0) {
			debouncedGetSubScenerio();
		} else {
			setSubScenerio([]);
		}
	}, [addcohort.scenario_ids]);

	const getAllCompany = async () => {
		try {
			const res = await axios.get(`${baseUrl}/getAllCompanies`, {
				headers: {
					Authorization: token
				}
			});
			if (res.data.success) {
				setAllCompany(res.data.companies);
			}
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		} finally {
			setIsLoading(false);
		}
	};

	const getSubScenerio = async () => {
		try {
			const response = await axios.post(
				`${baseUrl}/api/scenario/getSubScenario`,
				{ ids: addcohort.scenario_ids },
				{
					headers: {
						Authorization: token
					}
				}
			);

			if (response.data) {
				setSubScenerio(response.data);
				if (addcohort.subScenario_ids.length > 0) {
					setAddcohort(prevState => {
						const newSubScenarioIds = response.data
							.filter((item: { scenario_id: any }) => prevState.subScenario_ids.includes(item.scenario_id))
							.map((item: { scenario_id: any }) => item.scenario_id);
						return { ...prevState, subScenario_ids: newSubScenarioIds };
					});
				}
			}
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		} finally {
			setIsLoading(false);
		}
	};
	const debouncedGetSubScenerio = useCallback(debounce(getSubScenerio, 300), [addcohort.scenario_ids]);
	const getAllScenerio = async () => {
		try {
			const res = await axios.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			});
			if (res.data) {
				setAllScenerio(res.data);
			}
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		} finally {
			setIsLoading(false);
		}
	};

	const inputHandler = (e: { target: { name: any; value: any } }) => {
		setAddcohort({ ...addcohort, [e.target.name]: e.target.value });
	};

	const scenerioHandler = (e: any) => {
		const value = parseInt(e.target.value, 10);
		setAddcohort(prevState => {
			const newScenarioIds = e.target.checked
				? [...prevState.scenario_ids, value]
				: prevState.scenario_ids.filter(id => id !== value);
			const newScenarioSequence = e.target.checked
				? [...prevState.scenarioSequence, value]
				: prevState.scenarioSequence.filter(id => id !== value);
			return {
				...prevState,
				scenario_ids: newScenarioIds,
				scenarioSequence: newScenarioSequence
			};
		});
	};

	const subScenerioHandler = (e: any) => {
		const value = parseInt(e.target.value, 10);
		setAddcohort(prevState => {
			const newSubScenarioIds = e.target.checked
				? [...prevState.subScenario_ids, value]
				: prevState.subScenario_ids.filter(id => id !== value);
			return { ...prevState, subScenario_ids: newSubScenarioIds };
		});
	};

	const handleDragStart = (e: React.DragEvent<HTMLLIElement>, index: number) => {
		e.dataTransfer.setData('text/plain', index.toString());
	};

	const handleDrop = (e: React.DragEvent<HTMLLIElement>, index: number) => {
		e.preventDefault();
		const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
		setAddcohort(prevState => {
			const newScenarioSequence = [...prevState.scenarioSequence];
			const [removed] = newScenarioSequence.splice(draggedIndex, 1);
			newScenarioSequence.splice(index, 0, removed);
			return { ...prevState, scenarioSequence: newScenarioSequence };
		});
	};

	const allowDrop = (e: React.DragEvent<HTMLLIElement>) => {
		e.preventDefault();
	};

	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisabled(true);
		if (addcohort.scenario_ids.length === 0) {
			setIsDisabled(false);
			alert('Select scenarios');
			return;
		}
		if (addcohort.subScenario_ids.length === 0) {
			setIsDisabled(false);
			alert('Select sub-scenarios');
			return;
		}
		try {
			await axios.post(`${baseUrl}/api/admin/cohort`, addcohort, {
				headers: {
					Authorization: token
				}
			});
			setOpen(false);
			setIsRefresh(!isRefresh);
		} catch (error) {
			console.log(error);
			setOpen(false);
			unhandleToken(error);
		} finally {
			setIsDisabled(false);
		}
	};

	return (
		<>
			<Box sx={style}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
					<Typography variant="h6">Create Cohort</Typography>
					<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setOpen(false)}>
						<HighlightOffOutlinedIcon />
					</Button>
				</Box>
				{isLoading ? (
					<Typography>Loading data, please wait...</Typography>
				) : (
					<>
						<form onSubmit={submitHandler}>
							<FormControl fullWidth sx={{ gap: 2 }}>
								<Box>
									<Autocomplete
										id="company-autocomplete"
										options={allCompany}
										getOptionLabel={(option: { name: string; id: number }) => option.name}
										onChange={(event, value) =>
											inputHandler({
												target: { name: 'company_id', value: value ? value.id : '' }
											})
										}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={params => (
											<TextField {...params} label="Select Company" placeholder="Select Company" fullWidth required />
										)}
									/>
								</Box>
								<Box>
									<Typography>Cohort Name</Typography>
									<TextField name="name" required value={addcohort.name} onChange={inputHandler} fullWidth />
								</Box>
								<Box>
									<Typography>Scenario Categories</Typography>
									<ul className="ks-cboxtags ">
										{allScenerio &&
											allScenerio
												.filter((item: any) => item.parent_id === null)
												.map((items: any, index: number) => (
													<li key={index} className="mr-1">
														<input
															type="checkbox"
															id={items.scenario_id}
															value={items.scenario_id}
															onChange={scenerioHandler}
														/>
														<label htmlFor={items.scenario_id}>{items.scenario_name}</label>
													</li>
												))}
									</ul>
								</Box>
								<Box>
									<Typography>Sub Scenario Categories</Typography>
									<ul className="ks-cboxtags">
										{subScenerio.length > 0 &&
											subScenerio.map((items: any, index: number) => (
												<li key={index} className="mr-1">
													<input
														type="checkbox"
														id={items.scenario_id}
														value={items.scenario_id}
														checked={addcohort.subScenario_ids.includes(items.scenario_id)}
														onChange={subScenerioHandler}
													/>
													<label htmlFor={items.scenario_id}>{items.scenario_name}</label>
												</li>
											))}
									</ul>
								</Box>
								<Box>
									<Typography>Scenario Sequence</Typography>
									<ul
										className="ks-cboxtags"
										style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0 }}>
										{addcohort.scenarioSequence.map((scenarioId: any, index: number) => (
											<li
												key={index}
												draggable
												onDragStart={e => handleDragStart(e, index)}
												onDrop={e => handleDrop(e, index)}
												onDragOver={allowDrop}
												className="draggable-item"
												style={{
													display: 'flex',
													alignItems: 'center',
													marginBottom: '8px',
													padding: '8px',
													border: '1px solid #ccc',
													borderRadius: '4px',
													backgroundColor: '#f9f9f9',
													cursor: 'grab'
												}}>
												<span style={{ marginRight: '8px' }}>{index + 1}.</span>
												{allScenerio
													.filter((s: any) => s.scenario_id === scenarioId)
													.map((items: any) => items.scenario_name)}
											</li>
										))}
									</ul>
								</Box>
								<Box>
									<Typography>Passing Score</Typography>
									<TextField
										type="number"
										name="scenario_passing_score"
										required
										value={addcohort.scenario_passing_score}
										onChange={inputHandler}
										fullWidth
										inputProps={{
											min: 25,
											max: 100
										}}
									/>
								</Box>
								<Box>
									<Typography>Min. Passed Scenario</Typography>
									<TextField
										type="number"
										name="min_passed_scenario"
										required
										value={addcohort.min_passed_scenario}
										onChange={inputHandler}
										fullWidth
									/>
								</Box>
								<Box>
									<Typography>Maximum User</Typography>
									<TextField
										type="number"
										name="maxUser"
										required
										value={addcohort.maxUser}
										onChange={inputHandler}
										fullWidth
									/>
								</Box>
							</FormControl>
							<Box sx={{ textAlign: 'right' }}>
								<Button type="submit" variant="contained" disabled={isDisabled}>
									Save
								</Button>
							</Box>
						</form>
					</>
				)}
			</Box>
		</>
	);
};

export default AddCohort;
