import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

// Styled dot component with fade-in and fade-out animation
const Dot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: "#bbb", // Neutral dot color
  margin: "0 4px",
  animation: "fade 1.2s infinite ease-in-out",
  "@keyframes fade": {
    "0%, 80%, 100%": {
      opacity: 0.3, // Dots dimmed
    },
    "40%": {
      opacity: 1, // Highlighted dot
    },
  },
}));

// Main Typing Indicator Component
const TypingIndicator = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#f1f1f1", // Speech bubble color
        borderRadius: "16px",
        padding: "8px 12px",
        width: "80px", // Bubble width
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Typing Dots */}
      <Dot sx={{ animationDelay: "0s" }} />
      <Dot sx={{ animationDelay: "0.2s" }} />
      <Dot sx={{ animationDelay: "0.4s" }} />
    </Box>
  );
};

export default TypingIndicator;
