import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	SvgIcon,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import React, { useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import CancelIcon from '@mui/icons-material/Cancel';
import { fireStorage } from '../../../config/firebase';
import DescriptionIcon from '@mui/icons-material/Description';
import { getFileNameFromUrl } from '../../../Helper/encodeURI';
import AudioCard from './AudioCard';
import { voiceModalOptions } from '../../../Helper/TextToSpeech';
import { Label } from '@mui/icons-material';
import { getIcon } from '../../../utils/svgIcons';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	maxHeight: '90%',
	overflow: 'auto'
};

interface CreateCategoryProps {
	setOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	imageData: ImageData[];
	parent_id?: number;
	video_url?: any;
	voice_modal?: any;
}

type scenarioType = {
	scenario_name: string;
	parent_id: number | null;
	category: string;
	picture_file_id: string;
	description: string;
	summary: string;
	role_name: string;
	video_url: string | null;
	voice_modal: string | null;
	badge_file_id: string | null;
};

interface ImageData {
	id: string;
	name: string;
	usage: string;
	title: string;
}

const CreateCategory: React.FC<CreateCategoryProps> = ({ setOpen, imageData, setIsRefresh, parent_id }) => {
	const [scenarioData, setScenarioData] = useState<scenarioType>({
		scenario_name: '',
		parent_id: parent_id || null,
		category: '',
		picture_file_id: '',
		description: '',
		role_name: '',
		video_url: null,
		voice_modal: null,
		badge_file_id: null,
		summary: ''
	});

	const [isDisable, setIsDisable] = useState(true);
	const token = getToken();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const formHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
		if (isDisable) {
			setIsDisable(false);
		}
		const { name, value } = e.target;
		setScenarioData(prevState => ({
			...prevState,
			[name as string]: value,
			...(name === 'scenario_name' && { category: value as string }) // Update category if scenario_name is updated
		}));
	};

	const [videos, setVideos] = useState<(File | string)[]>([]);

	const [loadingForVideo, setLoadingForVideo] = useState(false);
	const [isVideo, setIsVideo] = useState<boolean>(false);
	const [selectedVoiceModal, setSelectedVoiceModal] = useState<any>(null);
	const { unhandleToken } = useHandleToken();
	const [voiceMode, setVoiceMode] = useState<boolean>(false);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			const newVideos = Array.from(files);
			setVideos(prevVideos => [...prevVideos, ...newVideos]);
			setIsDisable(false);
		}
	};

	const handleVideoDelete = (index: number) => {
		setIsDisable(false);
		setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
	};

	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisable(true);

		let videoUrls = videos;

		const uploadPromises = videos.map(async video => {
			if (typeof video !== 'string') {
				const fileName = `${new Date().getTime()}-${video?.name}`;
				const storageRef = ref(
					fireStorage,
					video?.type?.includes('video') ? `scenario_videos/${fileName}` : `scenario_texts/${fileName}`
				);
				const snapshot = await uploadBytes(storageRef, video);
				return await getDownloadURL(snapshot.ref);
			}
			return video;
		});

		setLoadingForVideo(true);
		videoUrls = await Promise.all(uploadPromises);
		setLoadingForVideo(false);

		await axios
			.post(
				`${baseUrl}/api/scenario/addScenario`,
				{
					...scenarioData,
					video_url: videoUrls?.join(','),
					enable_voice_mode: voiceMode,
					voice_modal: selectedVoiceModal
				},
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(res => {
				if (res?.data?.success) {
					setScenarioData({
						scenario_name: '',
						parent_id: null,
						category: '',
						picture_file_id: '',
						description: '',
						role_name: '',
						video_url: null,
						voice_modal: null,
						badge_file_id: null,
						summary: ''
					});
					setOpen(false);
					setIsRefresh(true);
				}
				setIsDisable(false);
			})
			.catch(e => {
				console.log(e);
				setIsDisable(false);
				unhandleToken(e);
			});
	};
	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Add Category</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			<form onSubmit={submitHandler}>
				<FormControl fullWidth sx={{ gap: 2 }}>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Scenario Name"
							name="scenario_name"
							type="text"
							value={scenarioData.scenario_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Description"
							name="description"
							type="text"
							value={scenarioData.description}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Summary"
							name="summary"
							type="text"
							value={scenarioData.summary}
							onChange={formHandler}
							fullWidth
							inputProps={{
								maxLength: 215
							}}
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Role"
							name="role_name"
							type="text"
							value={scenarioData.role_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<Autocomplete
							id="picture-autocomplete"
							options={Array.isArray(imageData) ? imageData.filter(item => item?.usage?.includes('profile')) : []}
							getOptionLabel={option => option.title || ''}
							onChange={(event, value) => {
								formHandler({ target: { name: 'picture_file_id', value: value?.id || '' } } as any);
							}}
							value={imageData?.find(item => item.id === scenarioData.picture_file_id) || null}
							isOptionEqualToValue={(option, value) => option.id === value?.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Select Picture"
									placeholder="Select Picture"
									fullWidth
									required
									InputProps={{
										...params.InputProps,
										startAdornment: scenarioData?.picture_file_id ? (
											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginRight: 1 }}>
												{(() => {
													const icon = getIcon(
														imageData?.find(item => item.id === scenarioData.picture_file_id)?.name || ''
													);
													return icon ? (
														<SvgIcon component={icon} viewBox="0 0 384 512" sx={{ fontSize: '2rem', width: '1.1em' }} />
													) : null;
												})()}
											</Box>
										) : null
									}}
								/>
							)}
							renderOption={(props, option) => (
								<Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									{(() => {
										const icon = getIcon(option.name);
										return icon ? (
											<SvgIcon component={icon} viewBox="0 0 384 512" sx={{ fontSize: '2rem', width: '1.1em' }} />
										) : null;
									})()}
									<Typography>{option.title}</Typography>
								</Box>
							)}
						/>
					</Box>
					{parent_id ? (
						''
					) : (
						<Box>
							<Autocomplete
								id="badge-autocomplete"
								options={Array.isArray(imageData) ? imageData.filter(item => item?.usage?.includes('badge')) : []}
								getOptionLabel={option => option.title || ''}
								onChange={(event, value) => {
									formHandler({ target: { name: 'badge_file_id', value: value?.id || '' } } as any);
								}}
								value={imageData?.find(item => item.id === scenarioData.badge_file_id) || null}
								isOptionEqualToValue={(option, value) => option.id === value?.id}
								renderInput={params => (
									<TextField
										{...params}
										label="Select Badge"
										placeholder="Select Badge"
										fullWidth
										required
										InputProps={{
											...params.InputProps,
											startAdornment: scenarioData?.badge_file_id ? (
												<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginRight: 1 }}>
													{(() => {
														const icon = getIcon(
															imageData?.find(item => item.id === scenarioData.badge_file_id)?.name || ''
														);

														return icon ? (
															<SvgIcon
																component={icon}
																viewBox="0 0 384 512"
																sx={{ fontSize: '2rem', width: '1.1em' }}
															/>
														) : null;
													})()}
												</Box>
											) : null
										}}
									/>
								)}
								renderOption={(props, option) => (
									<Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
										{(() => {
											const icon = getIcon(option.name);
											return icon ? (
												<SvgIcon component={icon} viewBox="0 0 384 512" sx={{ fontSize: '2rem', width: '1.1em' }} />
											) : null;
										})()}
										<Typography>{option.title}</Typography>
									</Box>
								)}
							/>
						</Box>
					)}
					{parent_id ? (
						<>
							<Box>
								<label style={{ marginRight: '10px' }}>Voice mode</label>
								<FormControlLabel
									control={
										<Switch
											checked={voiceMode}
											onChange={() => {
												setVoiceMode(!voiceMode);
												setIsDisable(false);
											}}
										/>
									}
									label=""
								/>
							</Box>
							{voiceMode && (
								<Box>
									<label>Voice Modals</label>
									<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '100%', marginTop: '4px' }}>
										{Array.isArray(imageData) &&
											voiceModalOptions.map((item: any, index: number) => (
												<AudioCard
													item={item}
													key={index}
													setSelectedVoiceModal={e => {
														setIsDisable(false);
														setSelectedVoiceModal(e);
													}}
													selectedVoiceModal={selectedVoiceModal}
												/>
											))}
									</div>
								</Box>
							)}
						</>
					) : (
						''
					)}
					<Box>
						<div style={{ marginBottom: '6px' }}>Backstory</div>
						{loadingForVideo ? (
							<LinearProgress />
						) : (
							<>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '10px',
										width: '100%'
									}}
								>
									{videos.map((video, index) => (
										<Box key={index} display={'flex'} gap={1}>
											{(typeof video === 'string' && video?.includes('scenario_videos')) ||
											(typeof video !== 'string' && video?.type?.includes('video')) ? (
												<video
													controls
													style={{ maxWidth: '300px' }}
													src={typeof video === 'string' ? video : URL.createObjectURL(video)}
												>
													Your browser does not support to load the video.
												</video>
											) : (
												<div
													onClick={() => {
														if (typeof video === 'string') {
															window.open(video, '_blank');
														}
													}}
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: 1,
														cursor: typeof video === 'string' ? 'pointer' : 'auto'
													}}
												>
													<DescriptionIcon color="primary" fontSize="large" />
													<p>{typeof video !== 'string' ? video?.name && video?.name : getFileNameFromUrl(video)}</p>
												</div>
											)}
											<CancelIcon onClick={() => handleVideoDelete(index)} sx={{ color: 'red', cursor: 'pointer' }} />
										</Box>
									))}
								</Box>
								<input
									style={{
										margin: '16px 0'
									}}
									accept=".mp4,.webm,.ogg,.txt"
									id="contained-button-file"
									type="file"
									multiple
									onChange={handleFileChange}
								/>
							</>
						)}
					</Box>
					<Box>
						<Button type="submit" disabled={isDisable}>
							Submit
						</Button>
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default CreateCategory;
