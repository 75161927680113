import { Alert, Box, Button, FormControlLabel, Slide, Snackbar, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './ScenarioCreate.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { getToken, useHandleToken } from '../../../../Helper/GetToken';
import axios from 'axios';
// Define the type for rubrics
interface Rubrics {
	[key: string]: {
    tooltip: string;
		description: string;
		score: Record<string, string>;
	};
}
interface Internal_State {
	[key: string]: {
		behavior: string;
		score_range: string;
	};
}

const ScenarioCreation = () => {
	const [scenarioData, setScenarioData] = useState<{
		characterImage: string;
		characterName: string;
		characterPosition: string;
		story: string;
		character: string;
		rubrics: Rubrics;
		internal_state: Internal_State;
		model: string;
		direct_model: boolean;
		facts?: string;
		stories: string;
	}>({
		characterImage: '',
		characterName: '',
		characterPosition: '',
		story: '',
		character: '',
		rubrics: {},
		internal_state: {
			very_low: {
				score_range: '',
				behavior: ''
			},
			low: {
				score_range: '',
				behavior: ''
			},
			medium: {
				score_range: '',
				behavior: ''
			},
			high: {
				score_range: '',
				behavior: ''
			}
		},
		model: '',
		direct_model: false,
		facts: '',
		stories: ''
	});
	const [subScenario, setSubScenario] = useState([]);
	const [parentScenario, setParentScenario] = useState([]);
	const [selectedParentScenario, setSelectedParentScenario] = useState('');
	const [selectedSubScenario, setSelectedSubScenario] = useState('');
	const [newRubricName, setNewRubricName] = useState('');
	const [newRubricDescription, setNewRubricDescription] = useState('');
	const [newRubricTooltipDescription, setNewRubricTooltipDescription] = useState('');
	const [selectedRubric, setSelectedRubric] = useState<string | null>(null);
	const [newBehaviorKey, setNewBehaviorKey] = useState('');
	const [newBehaviorValue, setNewBehaviorValue] = useState('');
	const [open, setOpen] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const vertical = 'top';
	const horizontal = 'right';
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getScenarios();
	}, []);
	useEffect(() => {
		if (selectedParentScenario) {
			getSubScenarios();
		}
	}, [selectedParentScenario]);

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setParentScenario(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const getSubScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				},
				params: { id: selectedParentScenario }
			})
			.then(res => {
				if (res.data) {
					setSubScenario(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const imageHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64 = reader.result as string;
				setScenarioData({ ...scenarioData, [e.target.name]: base64 });
			};
			reader.readAsDataURL(file);
		}
	};

	const inputHandler = (
		e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
		rubricKey?: string
	) => {
		if (rubricKey) {
			const updatedRubrics = { ...scenarioData.rubrics };
			const field = e.target.name;
			updatedRubrics[rubricKey] = {
				...updatedRubrics[rubricKey],
				[field]: e.target.value
			};
			setScenarioData({ ...scenarioData, rubrics: updatedRubrics });
		} else {
			setScenarioData({ ...scenarioData, [e.target.name]: e.target.value });
		}
	};
	const scoreRange_Handler = async (e: React.ChangeEvent<HTMLInputElement>, rangeKey: string) => {
		const updatedRange = { ...scenarioData.internal_state };
		const field = e.target.name;
		updatedRange[rangeKey] = {
			...updatedRange[rangeKey],
			[field]: e.target.value
		};
		setScenarioData({ ...scenarioData, internal_state: updatedRange });
	};
	const addRubric = () => {
		if (newRubricName && newRubricDescription && newRubricTooltipDescription) {
			setScenarioData(prevData => ({
				...prevData,
				rubrics: {
					...prevData.rubrics,
					[newRubricName]: { description: newRubricDescription, tooltip: newRubricTooltipDescription, score: {} }
				}
			}));
			setNewRubricName('');
			setNewRubricDescription('');
			setNewRubricTooltipDescription('');
		}
	};

	const removeRubric = (rubricName: string) => {
		const updatedRubrics = { ...scenarioData.rubrics };
		delete updatedRubrics[rubricName];
		setScenarioData({ ...scenarioData, rubrics: updatedRubrics });
		setSelectedRubric(null); // Clear selected rubric if it's removed
	};

	const addBehavior = () => {
		if (selectedRubric && newBehaviorKey && newBehaviorValue) {
			const updatedRubrics = { ...scenarioData.rubrics };
			updatedRubrics[selectedRubric].score[newBehaviorKey] = newBehaviorValue;
			setScenarioData({ ...scenarioData, rubrics: updatedRubrics });
			setNewBehaviorKey('');
			setNewBehaviorValue('');
		}
	};

	const removeBehavior = (behaviorKey: string) => {
		if (selectedRubric) {
			const updatedRubrics = { ...scenarioData.rubrics };
			delete updatedRubrics[selectedRubric].score[behaviorKey];
			setScenarioData({ ...scenarioData, rubrics: updatedRubrics });
		}
	};
	const handleChange = (event: { target: { value: string } }) => {
		setSelectedParentScenario(event.target.value);
		setSelectedSubScenario('');
	};
	const formHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		const scenario_id = selectedSubScenario ? selectedSubScenario : selectedParentScenario;

		try {
			const response = await axios.post(
				`${baseUrl}/api/scenario/generatePromts/${scenario_id}`,
				{ data: scenarioData },
				{
					headers: {
						Authorization: token
					}
				}
			);
			// console.log(response.data.success);
			if (response.data.success) {
				setScenarioData({
					characterImage: '',
					characterName: '',
					characterPosition: '',
					story: '',
					character: '',
					rubrics: {},
					internal_state: {
						very_low: {
							score_range: '',
							behavior: ''
						},
						low: {
							score_range: '',
							behavior: ''
						},
						medium: {
							score_range: '',
							behavior: ''
						},
						high: {
							score_range: '',
							behavior: ''
						}
					},
					direct_model: false,
					model: '',
					facts: '',
					stories: ''
				});
				setSelectedParentScenario('');
				setSelectedSubScenario('');
				setSelectedRubric(null);
				setOpen(true);
			}
		} catch (error) {
			console.error(error);
			unhandleToken(error);
		}
	};
	const handleToggleChange = (event: { target: { checked: boolean } }) => {
		const { checked } = event.target;
		setScenarioData(prevData => ({
			...prevData,
			direct_model: checked
		}));
	};
	return (
		<>
			<Box sx={{ padding: '10px 30px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
					<Box>
						<Typography
							variant="h6"
							sx={{
								color: 'rgba(22, 22, 22, 1)',
								fontFamily: 'IBM Plex Sans',
								fontSize: '32px',
								fontWeight: '600',
								lineHeight: '36px'
							}}>
							Simulation Scenario Creation
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: 'rgba(51, 51, 51, 1)',
								fontFamily: 'IBM Plex Sans',
								fontSize: '18px',
								lineHeight: '26px',
								letterSpacing: '0.16px'
							}}>
							Edit the fields below to create your own simsola scenario
						</Typography>
					</Box>
				</Box>
				<form onSubmit={formHandler}>
					<Box
						sx={{
							padding: '20px 0',
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							'@media (max-width: 1190px)': {
								justifyContent: 'start',
								gap: '25px'
							}
						}}>
						<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
							<div className="input-container">
								<FormControlLabel
									control={
										<Switch
											checked={scenarioData.direct_model}
											onChange={handleToggleChange}
											name="direct_model"
											color="primary"
										/>
									}
									label="Direct Model"
								/>
							</div>
							<div className="input-container">
								<input
									type="text"
									name="model"
									id="model"
									className="formInput"
									placeholder="Model Name"
									maxLength={200}
									onChange={inputHandler}
									value={scenarioData.model || ''}
									onKeyDown={e => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
									pattern="^[a-zA-Z1-9].*"
									title="Please enter a value starting with an alphanumeric character without leading whitespace"
									required={scenarioData.direct_model}
								/>
								<p className="char-count">{scenarioData.model?.length || 0}/200</p>
							</div>
						</Box>
					</Box>
					<Box
						sx={{
							padding: '20px 0',
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							'@media (max-width: 1190px)': {
								justifyContent: 'start',
								gap: '25px'
							}
						}}>
						<Box
							sx={{
								width: '32%',
								minWidth: '260px',
								'@media (max-width: 1190px)': {
									width: '100%',
									minWidth: '100%'
								}
							}}>
							<Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
								<label htmlFor="parentscenario" className="form-label">
									Select Scenario
								</label>
								<div className="input-container selectScenario-box">
									<select
										name="parentscenario"
										id="parentscenario"
										onChange={handleChange}
										required
										value={selectedParentScenario}>
										<option value="">Select Scenario</option>
										{parentScenario.length > 0 &&
											parentScenario.map((items: { scenario_id: string; scenario_name: string }, index: number) => {
												return (
													<>
														<option value={items.scenario_id} key={index}>
															{items.scenario_name}
														</option>
													</>
												);
											})}
									</select>
									<select
										name="parentscenario"
										id="parentscenario"
										onChange={e => setSelectedSubScenario(e.target.value)}
										value={selectedSubScenario}>
										<option value="">{subScenario.length > 0 ? 'Select Sub scenario' : 'No data'}</option>
										{subScenario.length > 0 &&
											subScenario.map((items: { scenario_id: string; scenario_name: string }, index: number) => {
												return (
													<>
														<option value={items.scenario_id} key={index}>
															{items.scenario_name}
														</option>
													</>
												);
											})}
									</select>
								</div>
							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'column', height: '239px' }}>
								<label htmlFor="characterphoto" className="form-label">
									Character Photo
								</label>
								{scenarioData.characterImage ? (
									<Box>
										<img src={scenarioData.characterImage} alt="Loading" className="characterImage" />
										<Button
											onClick={() => {
												setScenarioData({ ...scenarioData, characterImage: '' });
											}}>
											Change Image
										</Button>
									</Box>
								) : (
									<input
										type="file"
										name="characterImage"
										id="characterphoto"
										onChange={imageHandler}
										accept="image/*"
									/>
								)}
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="characterName" className="form-label">
									Character Name
								</label>
								<div className="input-container">
									<input
										type="text"
										name="characterName"
										id="characterName"
										className="formInput"
										placeholder="Name Here"
										maxLength={200}
										onChange={inputHandler}
										value={scenarioData.characterName || ''}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
											}
										}}
										pattern="^[a-zA-Z1-9].*"
										title="Please enter a value starting with an alphanumeric character without leading whitespace"
										required
									/>
									<p className="char-count">{scenarioData.characterName?.length || 0}/200</p>
								</div>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="characterDescription" className="form-label">
									Character Description
								</label>
								<div className="input-container">
									<textarea
										name="character"
										id="characterDescription"
										className="formInput"
										placeholder="Character Description Here"
										maxLength={1000}
										onChange={inputHandler}
										rows={5}
										required
										value={scenarioData.character || ''}></textarea>
									<p className="char-count">{scenarioData.character?.length || 0}/1000</p>
								</div>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="factsDescription" className="form-label">
									Facts
								</label>
								<div className="input-container">
									<textarea
										name="facts"
										id="factsDescription"
										className="formInput"
										placeholder="Facts Here"
										maxLength={1000}
										onChange={inputHandler}
										rows={5}
										required
										value={scenarioData.facts || ''}></textarea>
									<p className="char-count">{scenarioData.facts?.length || 0}/1000</p>
								</div>
							</Box>
						</Box>
						<Box
							sx={{
								width: '32%',
								minWidth: '260px',
								'@media (max-width: 1190px)': {
									width: '100%',
									minWidth: '100%'
								}
							}}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<label htmlFor="rubricName" className="form-label">
									Rubric
								</label>
								<Box sx={{ height: '276px', overflowY: 'scroll' }}>
									{Object.keys(scenarioData.rubrics).map((rubricKey, index) => (
										<Box
											key={index}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												mb: '20px',
												borderBottom: '1px solid #00000047',
												paddingBottom: '5px',
												gap: '5px'
											}}>
											<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
												<div className="input-container">
													<input
														type="text"
														name="name"
														className="formInput"
														placeholder="Name Here"
														maxLength={200}
														value={rubricKey}
														onKeyDown={e => {
															if (e.key === 'Enter') {
																e.preventDefault();
															}
														}}
														readOnly
													/>
												</div>
												<div className="input-container">
													<input
														type="text"
														name="description"
														className="formInput"
														placeholder="Description Here"
														maxLength={1000}
														onChange={e => inputHandler(e, rubricKey)}
														value={scenarioData.rubrics[rubricKey].description}
														onKeyDown={e => {
															if (e.key === 'Enter') {
																e.preventDefault();
															}
														}}
														readOnly
													/>
												</div>
												<div className="input-container">
													<input
														type="text"
														name="tooltip"
														className="formInput"
														placeholder="tooltip Here"
														maxLength={1000}
														onChange={e => inputHandler(e, rubricKey)}
														value={scenarioData.rubrics[rubricKey].tooltip}
														onKeyDown={e => {
															if (e.key === 'Enter') {
																e.preventDefault();
															}
														}}
														readOnly
													/>
												</div>
											</Box>
											<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
												{selectedRubric && selectedRubric === rubricKey ? (
													<Button onClick={() => setSelectedRubric(null)} variant="outlined">
														Selected
													</Button>
												) : (
													<Button onClick={() => setSelectedRubric(rubricKey)}>Select</Button>
												)}
												<Button
													size="small"
													variant="text"
													color="error"
													startIcon={<DeleteIcon />}
													sx={{ fontSize: '16px' }}
													onClick={() => removeRubric(rubricKey)}></Button>
											</Box>
										</Box>
									))}

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											mb: '20px',
											paddingBottom: '5px'
										}}>
										<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
											<div className="input-container">
												<input
													type="text"
													name="newRubricName"
													className="formInput"
													placeholder="Rubric Name"
													maxLength={200}
													onKeyDown={e => {
														if (e.key === 'Enter') {
															e.preventDefault();
														}
													}}
													onChange={e => setNewRubricName(e.target.value)}
													pattern="^[a-zA-Z1-9].*"
													title="Please enter a value starting with an alphanumeric character without leading whitespace"
													value={newRubricName}
												/>
											</div>
											<div className="input-container">
												<input
													type="text"
													name="newRubricDescription"
													className="formInput"
													placeholder="Rubric Description"
													maxLength={1000}
													onKeyDown={e => {
														if (e.key === 'Enter') {
															e.preventDefault();
														}
													}}
													onChange={e => setNewRubricDescription(e.target.value)}
													pattern="^[a-zA-Z1-9].*"
													title="Please enter a value starting with an alphanumeric character without leading whitespace"
													value={newRubricDescription}
												/>
											</div>
											<div className="input-container">
												<input
													type="text"
													name="newRubricTooltipDescription"
													className="formInput"
													placeholder="Rubric Tooltip Description"
													maxLength={1000}
													onKeyDown={e => {
														if (e.key === 'Enter') {
															e.preventDefault();
														}
													}}
													onChange={e => setNewRubricTooltipDescription(e.target.value)}
													pattern="^[a-zA-Z1-9].*"
													title="Please enter a value starting with an alphanumeric character without leading whitespace"
													value={newRubricTooltipDescription}
												/>
											</div>
										</Box>
										<Button onClick={addRubric}>Add</Button>
									</Box>
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="characterPosition" className="form-label">
									Character Position
								</label>
								<div className="input-container">
									<input
										type="text"
										name="characterPosition"
										id="characterPosition"
										className="formInput"
										placeholder="Position Here"
										maxLength={200}
										onChange={inputHandler}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
											}
										}}
										required
										pattern="^[a-zA-Z1-9].*"
										title="Please enter a value starting with an alphanumeric character without leading whitespace"
										value={scenarioData.characterPosition || ''}
									/>
									<p className="char-count">{scenarioData.characterPosition?.length || 0}/200</p>
								</div>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								{/* //previous name was Character Story */}
								<label htmlFor="characterStory" className="form-label">
									Scenario Description
								</label>
								<div className="input-container">
									<textarea
										name="story"
										id="characterStory"
										className="formInput"
										placeholder="Scenario Description Here"
										maxLength={1000}
										onChange={inputHandler}
										required
										rows={5}
										value={scenarioData.story || ''}></textarea>
									<p className="char-count">{scenarioData.story?.length || 0}/1000</p>
								</div>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="storiesDescription" className="form-label">
									Stories
								</label>
								<div className="input-container">
									<textarea
										name="stories"
										id="storiesDescription"
										className="formInput"
										placeholder="Stories Here"
										maxLength={1000}
										onChange={inputHandler}
										rows={5}
										required
										value={scenarioData.stories || ''}></textarea>
									<p className="char-count">{scenarioData.stories?.length || 0}/1000</p>
								</div>
							</Box>
						</Box>
						<Box
							sx={{
								width: '32%',
								minWidth: '260px',
								'@media (max-width: 1190px)': {
									width: '100%',
									minWidth: '100%'
								}
							}}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<label htmlFor="behavior" className="form-label">
									Behavior
								</label>
								<Box sx={{ height: '276px', overflowY: 'scroll' }}>
									<div className="input-container">
										{selectedRubric ? (
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												{Object.keys(scenarioData.rubrics[selectedRubric].score).map((behaviorKey, index) => (
													<Box
														key={index}
														sx={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '10px' }}>
														<Box sx={{ maxWidth: '100px' }}>
															<input
																type="text"
																name="behaviorKey"
																className="formInput keyInput"
																placeholder="Behavior Key"
																maxLength={200}
																value={behaviorKey}
																readOnly
															/>
														</Box>
														<input
															type="text"
															name="behaviorValue"
															className="formInput"
															placeholder="Behavior Value"
															maxLength={1000}
															value={scenarioData.rubrics[selectedRubric].score[behaviorKey]}
															readOnly
														/>
														<Button
															size="small"
															variant="outlined"
															color="error"
															onClick={() => removeBehavior(behaviorKey)}>
															-
														</Button>
													</Box>
												))}
												<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '10px' }}>
													<Box sx={{ maxWidth: '100px' }}>
														<input
															type="number"
															name="behaviorKey"
															className="formInput keyInput"
															placeholder="Range"
															maxLength={200}
															onKeyDown={e => {
																if (e.key === 'Enter') {
																	e.preventDefault();
																}
															}}
															onChange={e => setNewBehaviorKey(e.target.value)}
															value={newBehaviorKey}
															pattern="^[a-zA-Z1-9].*"
														/>
													</Box>
													<input
														type="text"
														name="behaviorValue"
														className="formInput"
														placeholder="Behavior"
														maxLength={1000}
														onKeyDown={e => {
															if (e.key === 'Enter') {
																e.preventDefault();
															}
														}}
														onChange={e => setNewBehaviorValue(e.target.value)}
														value={newBehaviorValue}
														pattern="^[a-zA-Z1-9].*"
														title="Please enter a value starting with an alphanumeric character without leading whitespace"
													/>
													<Button variant="outlined" color="success" size="small" onClick={addBehavior}>
														+
													</Button>
												</Box>
											</Box>
										) : (
											<Box>
												<Typography
													sx={{
														color: '#8E90A8',
														fontFamily: 'IBM Plex Sans',
														fontSize: '14px',
														fontWeight: '400',
														letterSpacing: '0.16px'
													}}>
													Please select a rubric so you can edit behavior for the stages.
												</Typography>
											</Box>
										)}
									</div>
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px 0', position: 'relative' }}>
								<label htmlFor="scoreRange" className="form-label">
									Score Range
								</label>
								<div className="input-container rangeFlex">
									<div className="rangeFlex-firstbox">
										<input
											type="text"
											id="scoreRange"
											name="score_range"
											placeholder="Very Low"
											maxLength={6}
											onChange={e => scoreRange_Handler(e, 'very_low')}
											value={scenarioData.internal_state.very_low.score_range}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											className="formInput"
											pattern="\d+-\d+"
											title="Please enter a valid score range (e.g., 0-1, 10-11)"
											required
										/>
									</div>
									<div className="input-container">
										<input
											name="behavior"
											id="scoreRange"
											className="formInput"
											placeholder="Behavior"
											maxLength={1000}
											onChange={e => scoreRange_Handler(e, 'very_low')}
											value={scenarioData?.internal_state?.very_low?.behavior || ''}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											required
											pattern="^[a-zA-Z1-9].*"
											title="Please enter a value starting with an alphanumeric character without leading whitespace"
										/>
										<p className="char-count">{scenarioData?.internal_state?.very_low?.behavior.length || 0}/1000</p>
									</div>
								</div>
								<div className="input-container rangeFlex">
									<div className="rangeFlex-firstbox">
										<input
											type="text"
											id="scoreRange"
											name="score_range"
											placeholder="Low"
											maxLength={6}
											onChange={e => scoreRange_Handler(e, 'low')}
											value={scenarioData.internal_state.low.score_range}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											className="formInput"
											pattern="\d+-\d+"
											title="Please enter a valid score range (e.g., 0-1, 10-11)"
											required
										/>
									</div>
									<div className="input-container">
										<input
											name="behavior"
											id="scoreRange"
											className="formInput"
											placeholder="Behavior"
											maxLength={1000}
											onChange={e => scoreRange_Handler(e, 'low')}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											value={scenarioData?.internal_state?.low?.behavior || ''}
											pattern="^[a-zA-Z1-9].*"
											title="Please enter a value starting with an alphanumeric character without leading whitespace"
											required
										/>
										<p className="char-count">{scenarioData?.internal_state?.low?.behavior.length || 0}/1000</p>
									</div>
								</div>
								<div className="input-container rangeFlex">
									<div className="rangeFlex-firstbox">
										<input
											type="text"
											id="scoreRange"
											name="score_range"
											placeholder="Medium"
											maxLength={6}
											value={scenarioData.internal_state.medium.score_range}
											onChange={e => scoreRange_Handler(e, 'medium')}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											className="formInput"
											pattern="\d+-\d+"
											title="Please enter a valid score range (e.g., 0-1, 10-11)"
											required
										/>
									</div>
									<div className="input-container">
										<input
											name="behavior"
											id="scoreRange"
											className="formInput"
											placeholder="Behavior"
											maxLength={1000}
											onChange={e => scoreRange_Handler(e, 'medium')}
											onKeyDown={(e: { key: string; preventDefault: () => void }) => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											value={scenarioData?.internal_state?.medium?.behavior || ''}
											pattern="^[a-zA-Z1-9].*"
											title="Please enter a value starting with an alphanumeric character without leading whitespace"
											required
										/>
										<p className="char-count">{scenarioData?.internal_state?.medium?.behavior.length || 0}/1000</p>
									</div>
								</div>
								<div className="input-container rangeFlex">
									<div className="rangeFlex-firstbox">
										<input
											type="text"
											id="scoreRange"
											name="score_range"
											placeholder="High"
											maxLength={6}
											value={scenarioData.internal_state.high.score_range}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											onChange={e => scoreRange_Handler(e, 'high')}
											pattern="\d+-\d+"
											title="Please enter a valid score range (e.g., 0-1, 10-11)"
											className="formInput"
											required
										/>
									</div>
									<div className="input-container">
										<input
											name="behavior"
											id="scoreRange"
											className="formInput"
											placeholder="Behavior"
											maxLength={1000}
											onChange={e => scoreRange_Handler(e, 'high')}
											value={scenarioData?.internal_state?.high?.behavior || ''}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
											pattern="^[a-zA-Z1-9].*"
											title="Please enter a value starting with an alphanumeric character without leading whitespace"
											required
										/>
										<p className="char-count">{scenarioData?.internal_state?.high?.behavior.length || 0}/1000</p>
									</div>
								</div>
							</Box>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Button type="submit" variant="contained" size="large" sx={{ padding: '5px 150px' }}>
							Generate
						</Button>
					</Box>
				</form>
			</Box>
			<Slide in={open} direction="left">
				<Snackbar
					open={open}
					autoHideDuration={5000}
					onClose={() => setOpen(false)}
					sx={{ padding: '10px 5px' }}
					anchorOrigin={{ vertical, horizontal }}>
					<Alert
						onClose={() => setOpen(false)}
						severity="error"
						variant="outlined"
						sx={{
							width: '100%',
							padding: '10px 5px',
							border: '1px solid rgba(5, 10, 58, 0.3)',
							borderRadius: '5px',
							background: 'white'
						}}>
						Saved Successfully!
					</Alert>
				</Snackbar>
			</Slide>
		</>
	);
};

export default ScenarioCreation;
