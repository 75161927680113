import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as generateUUID } from 'uuid';
import { ChannelE, ChatContext } from '../../../Contexts';
import { addConversation, getCurrentTimeStamp, updateConversation } from '../../../Helper/';
import { IChat, IConversation } from '../../../types';
import clock from '../../../Assets/Images/clock.png';
import mike from '../../../Assets/Images/mike.svg';
import AudioRecorder from '../../../Helper/AudioRecorder';
import { useChannel } from '../../../hooks/useChannel';
import getUser from '../../../Helper/getUser';
import { useDispatch, useSelector } from 'react-redux';
import {
	setConversationId,
	setEnableVoice,
	setRole,
	setSimulationValues
} from '../../../Helper/Redux/Action/userAction';
import useBackToDefault from '../../../Helper/helperFunction';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Notification from '../../Notification/Notification';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { Alert, Snackbar, Typography } from '@mui/material';
import { VoiceModalType } from '../../../types/voiceModal';
import { IValueHandler } from '../../../types/valueHandler';

const SimulationInput: React.FC = () => {
	const [enableMic, setEnableMic] = useState(false);
	const [Message, setMessage] = useState<string>('');
	const [callSendMsg, setCallSendMsg] = useState<boolean>(false);
	const [allMessages, setAllMessages] = useState<IChat[]>([]);
	const user = getUser();
	const tempUserId = user?.id;
	const searchParams = new URLSearchParams(location.search);
	const [timer, setTimer] = useState(0);
	const backtoDefault = useBackToDefault();
	const triggerHandleEnterRef = useRef(false);
	const dispatch = useDispatch();
	const [isUpdateAudioRecorder, setIsUpdateAudioRecorder] = useState<boolean>(false);
	const { sendMessage } = useChannel(ChannelE.simulation);
	const { roleName } = useSelector((state: any) => state?.role);
	const simulationValues = useSelector((state: any) => state?.simulation.simulationValues);
	const simulationId = useSelector((state: any) => state?.simulationId.simulationId);
	const { state } = useLocation();
	const [userTokens, setUserTokens] = useState<any>();
	const [isUserHaveTokens, setIsUserHaveTokens] = useState<boolean>(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'warning' | undefined;
		message?: string;
	}>();

	const [realtimePrompt, setRealtimePrompt] = useState<string>();
	const [voiceModal, setVoiceModal] = useState<VoiceModalType>('alloy');
	const { unhandleToken } = useHandleToken();

	const {
		messages,
		conversations,
		setConversations,
		selectedConversationId,
		setSelectedConversationId,
		chatLoading,
		setChatLoading,
		setMessages,
		setCoachSays,
		coachSays,
		messageType,
		setSimulationFeedbackRequire,
		setIsMessageAddedToDb,
		setIsTheLastMessage,
		isTheLastMessage,
		setDisplayTryAgain
	} = useContext(ChatContext);
	const sendInputRef: React.LegacyRef<HTMLTextAreaElement> | undefined = useRef(null);

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					const role = res.data.find((m: any) => m.scenario_id === roleName?.scenario_id);
					if (!role) {
						console.error('Role not found in member list');
						return;
					}
					dispatch(setRole(role));
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	useEffect(() => {
		getScenarios();
	}, []);

	const getPrompts = async () => {
		if (roleName?.scenario_id)
			await axios
				.get(`${baseUrl}/api/scenario/getPrompt/${roleName.scenario_id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (roleName?.voice_modal) {
						setVoiceModal(roleName?.voice_modal);
					}
					setRealtimePrompt(res?.data?.allPrompts.realtime_prompt);
				})
				.catch(e => {
					console.log(e);
				});
	};

	useEffect(() => {
		if (state?.notDefault) {
			return;
		}
		backtoDefault();
		getPrompts();
		// dispatch(clearConversationId());
	}, []);

	useEffect(() => {
		getUserTokens();
	}, [chatLoading]);

	const getUserTokens = async () => {
		await axios
			.get(`${baseUrl}/getUserTokens/${user.id}`, {
				headers: {
					Authorization: token
				}
			})
			.then((res: any) => {
				setUserTokens(res.data);
				setIsUserHaveTokens(res.data.remainingDailyTokens > 0);
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	useEffect(() => {
		if (searchParams?.get('simulationId') && selectedConversationId === searchParams?.get('simulationId')) {
			setSelectedConversationId(searchParams?.get('simulationId') || undefined);
			dispatch(setConversationId(searchParams?.get('simulationId') || null));
			const conversation = conversations?.find(
				conversation => conversation.conversationId === searchParams?.get('simulationId')
			);
			setMessages((conversation?.messages as IChat[]) || []);
			return;
		}
	}, [conversations]);

	const sendMsg = async (conversationId: string, messages: IChat[], msgPayload: IChat) => {
		setCallSendMsg(false);

		sendMessage({
			messages: messages,
			conversationId: conversationId,
			userId: tempUserId,
			message: msgPayload,
			roleName: roleName,
			simulationValues: simulationValues
		})
			.then((res: any) => {
				if (res) {
					setChatLoading(false);
					if (res.status === 'success' && res.values) {
						dispatch(setSimulationValues(res.values));
					} else {
						setDisplayTryAgain(true);
						setOpenAlert({
							open: true,
							type: res.status,
							message: res.message || 'Something went wrong!'
						});
					}
				}
			})
			.catch(e => {
				console.log('error while sending the message', e);
			});
	};
	const updateAudioRecorder = (value: boolean) => {
		setIsUpdateAudioRecorder(value);
	};
	const handleSendMsg = async (payload: IChat) => {
		// Implementation is the same as in SenderInput
		try {
			const msgPayload = { ...payload, messageId: generateUUID() };
			setMessages([...messages, msgPayload]);

			const conversationIdx = conversations.findIndex(
				conversation => conversation.conversationId === selectedConversationId
			);

			if (selectedConversationId !== undefined) {
				await updateConversation('simulation', tempUserId, selectedConversationId, msgPayload);
				setConversations((prevConversations: IConversation[]) => {
					prevConversations[conversationIdx].messages = [...prevConversations[conversationIdx].messages, msgPayload];
					return prevConversations;
				});
			} else {
				const newConversation = await addConversation(
					'simulation',
					tempUserId,
					msgPayload,
					roleName?.scenario_id,
					simulationId
					// conversationId
				);
				if (newConversation) {
					setConversations(
						newConversation?.conversations.filter(items => items.scenarioId === roleName?.scenario_id) || []
					);
					setSelectedConversationId(newConversation?.id || '');
					dispatch(setConversationId(newConversation?.id));
				}
			}

			setAllMessages([...messages, msgPayload]);
			setCallSendMsg(true);
			setDisplayTryAgain(false);
		} catch (error) {
			setDisplayTryAgain(true);
			console.log('error', error);
		}
	};

	const handleEnter = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		// Implementation is the same as in SenderInput
		if (event.key === 'Enter' && !event.shiftKey) {
			//we are not using form
			// event.preventDefault();

			if (sendInputRef.current && sendInputRef.current.value.trim() !== '') {
				sendInputRef.current.value = '';
			} else {
				return;
			}

			if (userTokens?.remainingDailyTokens === 0) {
				setIsUserHaveTokens(false);
				return;
			}

			const newChat = {
				messageType: 'client',
				message: Message,
				time: getCurrentTimeStamp()
			};
			setChatLoading(true);
			try {
				await handleSendMsg(newChat);
			} catch (e) {
				setChatLoading(false);
				setDisplayTryAgain(true);
				console.log('error while sending the message', e);
			}
		}
	};

	const [currentRole, setCurrentRole] = useState<string>('');

	const [clientMessage, setClientMessage] = useState<string>('');
	const [coachMessage, setCoachMessage] = useState<string>('');

	const [isMessagesAdded, setIsMessagesAdded] = useState<boolean>();
	const setIsMessageAddedTofalse = (userMessage: string, assistantMessage: string, isTheLastMessage: boolean) => {
		setIsMessagesAdded(false);
		setClientMessage(userMessage);
		setCoachMessage(assistantMessage);
		setIsTheLastMessage(isTheLastMessage);
	};

	const valueHandler = (data: IValueHandler) => {
		setMessage(data.message);
		setCurrentRole(data.role);
	};

	useEffect(() => {
		if (messageType === 'audio') {
			const userMsgPayload = {
				messageType: 'client',
				message: '',
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};
			const coachMsgPayload = {
				messageType: 'coach',
				message: '',
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};

			if (selectedConversationId !== undefined) {
				if (!isMessagesAdded) {
					setMessages([...messages, userMsgPayload, coachMsgPayload]);
					setIsMessagesAdded(true);
					setMessage('');
				} else {
					const updatedMessages = [...messages];
					for (let i = messages.length - 1; i >= 0; i--) {
						if (messages[i].messageType === currentRole) {
							currentRole === 'client'
								? (updatedMessages[i] = {
										...messages[i],
										message: (messages[i].message += Message.trim())
									})
								: (updatedMessages[i] = {
										...messages[i],
										message: Message.trim()
									});
							break;
						}
					}
					setMessages(updatedMessages);
				}
			}
			setAllMessages([...messages, userMsgPayload, coachMsgPayload]);
		}
	}, [Message, currentRole, isMessagesAdded]);

	const createNewConversation = async () => {
		try {
			if (selectedConversationId === undefined) {
				const newConversation = await addConversation(
					'simulation',
					tempUserId,
					undefined,
					roleName?.scenario_id,
					simulationId
				);

				if (newConversation) {
					setConversations(
						newConversation?.conversations.filter(items => items.scenarioId === roleName?.scenario_id) || []
					);
					setSelectedConversationId(newConversation?.id || '');
					dispatch(setConversationId(newConversation?.id));
					return true;
				}
			}
		} catch (e) {
			console.log('error while creating conversation', e);
			return true;
		}
		return true;
	};

	useEffect(() => {
		if (!isMessagesAdded) {
			addMessageToFirebase(clientMessage, coachMessage);
		}
	}, [isMessagesAdded]);

	const addMessageToFirebase = async (userMessage: string, assistantMessage: string) => {
		try {
			// if (userMessage.trim() !== '' && assistantMessage.trim() !== '') {
			const userMsgPayload = {
				messageType: 'client',
				message: userMessage.trim(),
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};
			const coachMsgPayload = {
				messageType: 'coach',
				message: assistantMessage.trim(),
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};
			const conversationIdx = conversations.findIndex(
				conversation => conversation.conversationId === selectedConversationId
			);

			if (selectedConversationId) {
				setIsMessageAddedToDb(false);
				await updateConversation('simulation', tempUserId, selectedConversationId, userMsgPayload);
				await updateConversation('simulation', tempUserId, selectedConversationId, coachMsgPayload);
				setSimulationFeedbackRequire(true);
				if (isTheLastMessage) {
					setIsMessageAddedToDb(true);
					setIsTheLastMessage(false);
				}

				if (conversations)
					setConversations((prevConversations: IConversation[]) => {
						if (prevConversations && prevConversations[conversationIdx]?.messages)
							prevConversations[conversationIdx].messages = [
								...prevConversations[conversationIdx].messages,
								userMsgPayload,
								coachMsgPayload
							];
						return prevConversations;
					});
			}
			// }
		} catch (e) {
			console.log('error while adding message to firebase', e);
		}
	};

	const handleTimerUpdate = (newDuration: number) => {
		setTimer(newDuration);
	};

	useEffect(() => {
		if (Message && triggerHandleEnterRef.current) {
			handleEnter({
				key: 'Enter',
				shiftKey: false
			} as React.KeyboardEvent<HTMLTextAreaElement>);

			// Reset the flag
			triggerHandleEnterRef.current = false;
		}
		// Add message as a dependency if you want this effect to run anytime 'message' changes
		// and the flag is true. If you only want it to run when specifically triggered by valueHandler,
		// you might not need 'message' in the dependency array.
	}, [Message]);
	// Send a message to the server
	// Check for the state to send message to the server
	useEffect(() => {
		if (callSendMsg && messageType === 'text') {
			sendMsg(selectedConversationId as string, allMessages, allMessages?.[allMessages?.length - 1]);
		}
	}, [callSendMsg]);
	useEffect(() => {
		if (enableMic) {
			dispatch(setEnableVoice(true));
		}
		dispatch(setEnableVoice(false));
	}, [enableMic]);
	useEffect(() => {
		if (!chatLoading && sendInputRef.current) {
			sendInputRef.current.focus();
		}
	}, [chatLoading]);
	return (
		<div className="sender-input-box">
			{openAlert && openAlert.open && openAlert.type === 'error' && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={openAlert?.open}
					autoHideDuration={5000}
					onClose={handleClose}>
					<Alert
						onClose={handleClose}
						severity={openAlert?.type}
						variant="filled"
						sx={{
							width: '20%',
							position: 'fixed',
							top: 0,
							right: 0,
							margin: '10px',
							zIndex: 9999
						}}>
						{openAlert?.message}
					</Alert>
				</Snackbar>
			)}
			{user?.role === 'client' && (
				<>
					<div className="inputtime">
						<img src={clock} alt="clock" />
						<p>{timer}/5:00</p>
					</div>
					<div className="inputbox inputBoxPosition">
						<textarea
							ref={sendInputRef}
							className="form-control"
							placeholder="Hello!"
							disabled={chatLoading || enableMic || !isUserHaveTokens}
							rows={3}
							onChange={event => {
								setMessage(event?.target.value);
								triggerHandleEnterRef.current = false;
							}}
							onKeyDown={event => handleEnter(event)}
						/>
						{roleName?.enable_voice_mode && (
							<button
								className="mikeButton btn circle-btn"
								onClick={() => {
									if (!coachSays && !chatLoading) {
										setCoachSays(false);
									}
									setEnableMic(!enableMic);
								}}
								style={{
									opacity: coachSays || chatLoading ? '0.5' : 1
								}}
								id="mikeButton"
								disabled={coachSays || chatLoading}>
								<img src={mike} alt="mike" />
							</button>
						)}
						{enableMic ? (
							<AudioRecorder
								key={isUpdateAudioRecorder ? 'update' : 'do not update'}
								valueHandler={valueHandler}
								onTimerUpdate={handleTimerUpdate}
								createNewConversation={createNewConversation}
								setIsMessageAddedTofalse={setIsMessageAddedTofalse}
								prompt={realtimePrompt}
								voiceModal={voiceModal}
							/>
						) : (
							''
						)}
					</div>
					{!isUserHaveTokens ? (
						<Notification text={'You have used all your daily tokens'} />
					) : (
						<Typography>Hit return to give your response.</Typography>
					)}{' '}
				</>
			)}
		</div>
	);
};

export default SimulationInput;
