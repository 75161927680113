import React from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

// import axios from 'axios';
// import { getToken, useHandleToken } from '../../../Helper/GetToken';
// import { setRole } from '../../../Helper/Redux/Action/userAction';

interface PreparationHeaderProps {
	title?: string;
	para?: string;
	dbName?: string;
}

const PreparationHeader: React.FC<PreparationHeaderProps> = () => {
	const { roleName } = useSelector((state: any) => state?.role);
	const [open, setOpen] = React.useState(false);
	const [loadingIframe, setLoadingIframe] = React.useState(true);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [selectedVideo, setSelectedVideo] = React.useState<any>();
	const handleClickOpen = (video: any) => {
		setOpen(true);
		setLoadingIframe(true);
		setSelectedVideo(video);
	};
	const handleClose = () => {
		setOpen(false);
	};

	// const { category } = useSelector((state: any) => state?.roleCategory) || [];
	// const dispatch = useDispatch();
	// const { unhandleToken } = useHandleToken();

	// const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	// const token = getToken();

	// useEffect(() => {
	// 	if (category) {
	// 		getScenarios();
	// 	}
	// }, []);

	// const getScenarios = async () => {
	// 	await axios
	// 		.get(`${baseUrl}/api/scenario/getUserSubScenarios`, {
	// 			headers: {
	// 				Authorization: token
	// 			},
	// 			params: { id: category?.scenario_id }
	// 		})
	// 		.then(res => {
	// 			if (res.data) {
	// 				const setRoleData = res.data.find((data: any) => data.scenario_id === roleName?.scenario_id);
	// 				dispatch(setRole(setRoleData));
	// 			}
	// 		})
	// 		.catch(e => {
	// 			console.log(e);
	// 			unhandleToken(e);
	// 		});
	// };

	return (
		<>
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box sx={styles.Box}>
					<div>
						{/* <Typography sx={styles.Title}>{title}</Typography>
        <Typography sx={styles.Para}>{para}</Typography> */}
						<Typography sx={styles.Title}>Simulation Scenario: {roleName?.scenario_name}</Typography>{' '}
						{/* <Typography sx={styles.Title}>{roleName?.name}</Typography> */}
						<Typography sx={styles.Para}>
							{roleName?.summary?.split('\n').map((line: string, index: number) => (
								<React.Fragment key={index}>
									You are in the preparation phase. Click NEXT button to start the simulation anytime. Here is the
									scenario: {line}
									<br />
								</React.Fragment>
							))}
						</Typography>
					</div>
					<div style={{ display: 'flex', gap: '10px' }}>
						{roleName?.scenario_prompts?.videos?.length > 0 &&
							JSON.parse(roleName?.scenario_prompts?.videos)?.map((item: any, index: number) => (
								<Button
									key={index}
									onClick={() => {
										handleClickOpen(item);
									}}
									variant="outlined"
									sx={{ textTransform: 'capitalize', ...styles.Button }}
								>
									{item?.title}
								</Button>
							))}
					</div>
				</Box>
			</div>
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
				<Typography
					variant="h5"
					sx={{ marginTop: '8px', display: 'flex', justifyContent: 'center', textTransform: 'capitalize' }}
					id="responsive-dialog-title"
				>
					{selectedVideo?.title}
				</Typography>
				{selectedVideo && (
					<DialogContentText style={{ marginTop: '4px', display: 'flex', justifyContent: 'center' }}>
						Click on video to start
					</DialogContentText>
				)}
				<DialogContent
					sx={{
						width: '600px',
						height: '100%'
					}}
				>
					<DialogContentText sx={{ height: '100%' }}>
						{selectedVideo ? (
							<video
								// src={`${process.env.PUBLIC_URL}/Videos/John-feedback.mp4`}
								src={selectedVideo.file}
								// autoPlay
								controls
								width={'100%'}
							/>
						) : (
							<>
								{loadingIframe && <p>Loading...</p>}
								<iframe
									width={'100%'}
									height={'100%'}
									src={selectedVideo}
									onLoad={() => setLoadingIframe(false)}
									onError={() => setLoadingIframe(false)}
									loading="lazy"
								/>
							</>
						)}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

const styles = {
	Box: {
		maxWidth: '70%',
		'@media (max-width: 1024px)': {
			maxWidth: '90%'
		}
	},
	Title: {
		color: '#161616',
		fontFamily: 'IBM Plex Sans',
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: '36px',
		pt: '15px',
		'@media (max-width: 1600px)': {
			fontSize: '22px',
			lineHeight: 'normal'
		}
	},
	Para: {
		color: '#161616',
		fontFamily: 'Outfit',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		pt: '15px  ',
		'@media (max-width: 1600px)': {
			fontSize: '15px',
			lineHeight: 'normal'
		},
		height: '85px',
		overflowY: 'auto'
	},
	Button: {
		borderRadius: '100px',
		border: '1px solid #1C75BA',
		p: '4px 20px',
		fontSize: '18px',
		fontWeight: 500,
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};
export default PreparationHeader;
