import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import {
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	CircularProgress,
	Box,
	LinearProgress,
	Autocomplete,
	Popover
} from '@mui/material';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { ChatContext } from '../../../Contexts';
import getUser from '../../../Helper/getUser';
import happyIcon from '../../../Assets/Images/Emoji/emoji_1-cropped.svg';
import neutralIcon from '../../../Assets/Images//Emoji/emoji_9-cropped.svg';
import sadIcon from '../../../Assets/Images/Emoji/emoji_2-cropped.svg';
import angryIcon from '../../../Assets/Images//Emoji/emoji_3-cropped.svg';

const Playground = () => {
	const user = getUser();

	// const [playgroundLoading, setPlaygroundLoading] = useState(false);
	const [selectedSimulation, setSelectedSimulation] = useState<any>();
	const [selectedScenario, setSelectedScenario] = useState<any>();
	const [systemPrompt, setSystemPrompt] = useState('');
	const [numberOfTurns, setNumberOfTurns] = useState<any>('');
	const [results, setResults] = useState<any>('');

	const { selectedPlayground, setSelectedPlayground, playgroundLoading, setPlaygroundLoading, setPreviousPlaygrounds } =
		useContext(ChatContext);

	const [scenarios, setScenarios] = useState<any[]>([]); // Use IPrompt[] as the type for promptData
	const token = getToken();

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || ''; // Ensure to handle undefined case
	const { unhandleToken } = useHandleToken();
	const handleStart = async () => {
		try {
			setPlaygroundLoading(true);
			const res = await axios.post(
				`${baseUrl}/api/admin/playground/generate-conversation`,
				{
					systemPrompt,
					numberOfTurns,
					simulation: selectedSimulation,
					scenario: selectedScenario
				},
				{
					headers: {
						Authorization: token || ''
					}
				}
			);

			if (res?.data?.data) {
				const data: any = await getPreviousResults();
				if (data?.length) {
					setSelectedPlayground(data[0]);
				}
				setResults(JSON.stringify(res?.data?.data));
				setPlaygroundLoading(false);
			}
		} catch (error) {
			setPlaygroundLoading(false);
			console.error(error);
			unhandleToken(error);
		}
	};

	useEffect(() => {
		getScenarios();
	}, []);

	const getScenarios = async () => {
		setPlaygroundLoading(true);

		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setScenarios(res.data);
					setPlaygroundLoading(false);
				}
			})
			.catch(e => {
				console.log(e);
				setPlaygroundLoading(false);
				unhandleToken(e);
			});
	};

	const getPreviousResults = async () => {
		try {
			const q = query(collection(db, 'playground'), where('createdBy', '==', user?.id), orderBy('time', 'desc'));
			const playgroundDocSnap = await getDocs(q);
			const prevResults = playgroundDocSnap?.docs;
			setPreviousPlaygrounds(prevResults.map(doc => doc.data()));

			return prevResults.map(doc => doc.data());
		} catch (error) {
			console.error('Error fetching data from Firestore:', error);
		}
	};

	useEffect(() => {
		if (selectedPlayground) {
			setResults(JSON.stringify(selectedPlayground?.conversations));
			setNumberOfTurns(selectedPlayground?.numberOfTurns);
			setSelectedScenario(scenarios?.find(item => item?.scenario_id == selectedPlayground?.scenarioId));
			setSelectedSimulation(scenarios?.find(item => item?.scenario_id == selectedPlayground?.simulationId));
			setSystemPrompt(selectedPlayground?.systemPrompt);
		}
	}, [selectedPlayground]);

	return (
		<>
			{playgroundLoading && <LinearProgress />}
			<div style={{ display: 'flex', gap: '20px' }}>
				<Button
					id="try-again-button-for-playground"
					hidden={true}
					disabled={playgroundLoading}
					onClick={() => {
						setResults('');
						setSelectedPlayground(null);
						setSelectedScenario(undefined);
						setSystemPrompt('');
						setNumberOfTurns('');
						setSelectedSimulation(undefined);
					}}
					className="btn blue-btn mb-3">
					Try again
				</Button>
				<div
					style={{
						width: '80%',
						padding: '20px',
						display: 'flex',
						flexDirection: 'column',

						gap: '25px',
						margin: 'auto'
					}}>
					<div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
						<FormControl sx={{ width: '40%' }}>
							<Autocomplete
								fullWidth
								disabled={playgroundLoading}
								value={selectedSimulation || null}
								onChange={(e, newValue) => setSelectedSimulation(newValue)}
								options={scenarios.filter(item => item?.parent_id === null)}
								getOptionLabel={option => option?.scenario_name || ''}
								renderInput={params => (
									<TextField
										{...params}
										label="Simulation Name"
										InputLabelProps={{
											shrink: true
										}}
									/>
								)}
								isOptionEqualToValue={(option, value) => option.id === value.id}
							/>
						</FormControl>

						{selectedSimulation &&
							scenarios.filter(item => item?.parent_id == selectedSimulation?.scenario_id)?.length > 0 && (
								<FormControl sx={{ width: '40%' }}>
									<Autocomplete
										fullWidth
										disabled={playgroundLoading}
										value={selectedScenario || null}
										onChange={(e, newValue) => setSelectedScenario(newValue)}
										options={scenarios.filter(item => item?.parent_id === selectedSimulation?.scenario_id)}
										getOptionLabel={option => option?.scenario_name || ''}
										renderInput={params => (
											<TextField
												{...params}
												label="Scenario Name"
												InputLabelProps={{
													shrink: true
												}}
											/>
										)}
										isOptionEqualToValue={(option, value) => option.id === value.id}
									/>
								</FormControl>
							)}

						<Button
							disabled={playgroundLoading || !selectedScenario || !numberOfTurns || !systemPrompt}
							variant="contained"
							color="success"
							style={{ width: '80px' }}
							onClick={handleStart}>
							Start {playgroundLoading && <CircularProgress size={20} />}
						</Button>
					</div>

					<Box sx={{ width: '200px' }}>
						<TextField
							InputLabelProps={{
								shrink: true // Force the label to shrink when a value is present
							}}
							fullWidth
							disabled={playgroundLoading}
							type="number"
							label="Number of turns"
							value={numberOfTurns}
							onChange={e => setNumberOfTurns(e.target.value)}
						/>
					</Box>

					<TextField
						InputLabelProps={{
							shrink: true // Force the label to shrink when a value is present
						}}
						disabled={playgroundLoading}
						fullWidth
						label="System Prompt"
						multiline
						rows={10}
						value={systemPrompt}
						onChange={e => setSystemPrompt(e.target.value)}
					/>

					{results?.length > 0 &&
						JSON.parse(results)?.map((item: any, index: number) => <ChatCard item={item} key={index} />)}

					{/* <TextField
						InputLabelProps={{
							shrink: true // Force the label to shrink when a value is present
						}}
						disabled={playgroundLoading}
						fullWidth
						label="Results"
						multiline
						value={results}
						onChange={e => setResults(e.target.value)}
						inputProps={{ readOnly: true }}
					/> */}
				</div>
			</div>
		</>
	);
};

const ChatCard = ({ item }: { item: any }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const popoverRef = useRef<HTMLDivElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
				handlePopoverClose();
			}
		};

		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<div style={{ minWidth: '90px', maxWidth: '90px', textAlign: 'left' }}>{item?.messageType?.toUpperCase()}</div>
				<div style={{ width: '2%' }}>:</div>
				<div style={{ width: '100%' }}>
					{item?.messageType === 'system' && item.totalScore >= 0 && (
						<>
							<img
								aria-describedby={id}
								onClick={handlePopoverOpen}
								alt="loading"
								src={(() => {
									if (item.totalScore == 3) {
										return happyIcon; // Icon for happy
									}
									if (item.totalScore == 2) {
										return neutralIcon; // Icon for neutral
									}
									if (item.totalScore == 1) {
										return sadIcon; // Icon for sad
									}
									if (item.totalScore == 0) {
										return angryIcon; // Icon for angry
									}
								})()}
								style={{
									width: '24px',
									height: '24px',
									marginRight: '10px'
								}}
							/>

							<Popover
								id={id}
								ref={popoverRef}
								open={open}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'center'
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
								onClose={handlePopoverClose}
								disableRestoreFocus>
								<div style={{ padding: '10px' }}>
									{item?.assess_json &&
										Object.keys(item?.assess_json)?.map((i, key) => {
											return (
												<div key={key} style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
													<div>{i}</div>
													<div>{(100 * ((item?.assess_json[i] as number) + 1)) / 4}%</div>
												</div>
											);
										})}
								</div>
							</Popover>
						</>
					)}
					{item?.message}
				</div>
			</div>
		</div>
	);
};

export default Playground;
