import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const SimulationList = ({ data = [], search = '', setSelectedSimulation, total }: any) => {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(5);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};

	useEffect(() => {
		if (search?.length) setPage(1);
	}, [search]);

	return (
		<TableContainer sx={{ borderRadius: '8px' }}>
			<Table>
				<TableHead
					sx={{
						background: '#CFE9FD',
						border: '1px solid rgba(224, 224, 224, 1)'
					}}
				>
					<TableRow>
						<TableCell align="left">Conversation Name</TableCell>
						<TableCell align="left">Dimensions</TableCell>
						<TableCell align="left">Average Score</TableCell>
						<TableCell align="left">Date Time</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.length > 0 &&
						data
							.filter(
								(item: any) =>
									item?.conversationName.toLowerCase().includes(search.toLowerCase()) ||
									Object.keys(item?.analyticsFeedback?.analytics)
										?.join(',')
										.toLowerCase()
										.includes(search.toLowerCase())
							)
							.slice((page - 1) * limit, page * limit)
							.map((item: any, cohortIndex: number) => (
								<TableRow
									sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
									key={`${item?.conversationName}-${cohortIndex}`}
								>
									<TableCell align="left">
										{item?.conversationName} ({item?.messages?.length} Messages)
									</TableCell>
									<TableCell
										align="left"
										dangerouslySetInnerHTML={{
											__html: Object.keys(item?.analyticsFeedback.analytics)
												?.map(i => {
													if (item?.analyticsFeedback.analytics[i]) {
														return `${i} - ${(100 * (item?.analyticsFeedback.analytics[i]?.score + 1)) / 4}`;
													}
												})
												.join('<br/>')
										}}
									/>
									<TableCell align="left">{item?.avgScore}</TableCell>
									<TableCell align="left">{moment(item?.time).format('MMM DD hh:mm A')}</TableCell>
									<TableCell
										sx={{ cursor: 'pointer', color: '#278BD8' }}
										align="left"
										onClick={() => {
											setSelectedSimulation(item);
										}}
									>
										View
									</TableCell>
								</TableRow>
							))}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={total}
				page={page - 1}
				onPageChange={handleChangePage}
				rowsPerPage={limit}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[5, 10, 20]}
			/>
		</TableContainer>
	);
};
