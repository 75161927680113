import { ReactComponent as AgreeAndBuildIcon } from '../Assets/Images/Badges/SVG_Agree_and_build.svg';
import { ReactComponent as ArgumentationIcon } from '../Assets/Images/Badges/SVG_Argumentation.svg';
import { ReactComponent as BrainstormStrategicSolutions } from '../Assets/Images/Badges/SVG_Brainstorm_strategic_solutions.svg';
import { ReactComponent as ClarifyActionsAndAccountability } from '../Assets/Images/Badges/SVG_Clarify_actions_and_accountability.svg';
import { ReactComponent as Clarity } from '../Assets/Images/Badges/SVG_Clarity.svg';
import { ReactComponent as CreatingSharedMeaning } from '../Assets/Images/Badges/SVG_Creating_Shared_Meaning.svg';
import { ReactComponent as EstablishingResolution } from '../Assets/Images/Badges/SVG_Establishing_Resolution.svg';
import { ReactComponent as EstablishingSafety } from '../Assets/Images/Badges/SVG_Establishing_Safety--.svg';
import { ReactComponent as Framing } from '../Assets/Images/Badges/SVG_Framing.svg';
import { ReactComponent as InvestigateWhatsImportant } from '../Assets/Images/Badges/SVG_Investigate_what’s_important.svg';
import { ReactComponent as InviteFeelings } from '../Assets/Images/Badges/SVG_Invite_feelings.svg';
import { ReactComponent as InviteOthersViews } from '../Assets/Images/Badges/SVG_Invite_others’_views.svg';
import { ReactComponent as Logic } from '../Assets/Images/Badges/SVG_Logic.svg';
import { ReactComponent as Persuasion04 } from '../Assets/Images/Badges/SVG_Persuasion-04.svg';
import { ReactComponent as Persuasion05 } from '../Assets/Images/Badges/SVG_Persuasion-05.svg';
import { ReactComponent as Persuasion17 } from '../Assets/Images/Badges/SVG_Persuasion-17.svg';
import { ReactComponent as Responsiveness } from '../Assets/Images/Badges/SVG_Responsiveness.svg';
import { ReactComponent as RestoringSafety } from '../Assets/Images/Badges/SVG_Restoring_Safety.svg';
import { ReactComponent as SeekAHigherPurpose } from '../Assets/Images/Badges/SVG_Seek_a_higher_purpose.svg';
import { ReactComponent as StepOutOfTheConversation } from '../Assets/Images/Badges/SVG_Step_out_of_the_conversation.svg';
import { ReactComponent as SummarizeTheirPerspective } from '../Assets/Images/Badges/SVG_Summarize_their_perspective.svg';
import { ReactComponent as TakeAccountability } from '../Assets/Images/Badges/SVG_Take_Accountability.svg';
import { ReactComponent as Understanding } from '../Assets/Images/Badges/SVG_Understanding.svg';

import { ReactComponent as ClientProfile } from '../Assets/Images/Profile/clientProfile.svg';
import { ReactComponent as ClientBlackAndBrown } from '../Assets/Images/Profile/Client_black_and_brown.svg';
import { ReactComponent as ClientBrown } from '../Assets/Images/Profile/Client_brown.svg';
import { ReactComponent as ClientCyan } from '../Assets/Images/Profile/Client_cyan.svg';
import { ReactComponent as ClientGold } from '../Assets/Images/Profile/Client_gold.svg';
import { ReactComponent as ClientGreen } from '../Assets/Images/Profile/Client_green.svg';
import { ReactComponent as ClientHeliotrope } from '../Assets/Images/Profile/Client_Heliotrope.svg';
import { ReactComponent as ClientOrange } from '../Assets/Images/Profile/Client_orange.svg';
import { ReactComponent as ClientPurple } from '../Assets/Images/Profile/Client_purple.svg';
import { ReactComponent as ManagerToMemberV1BlackAndBrown } from '../Assets/Images/Profile/Manager_to_member_v1_black_and_brown.svg';
import { ReactComponent as ManagerToMemberV1Brown } from '../Assets/Images/Profile/Manager_to_member_v1_brown.svg';
import { ReactComponent as ManagerToMemberV1Gold } from '../Assets/Images/Profile/Manager_to_member_v1_gold.svg';
import { ReactComponent as ManagerToMemberV1Green } from '../Assets/Images/Profile/Manager_to_member_v1_green.svg';
import { ReactComponent as ManagerToMemberV1Orange } from '../Assets/Images/Profile/Manager_to_member_v1_orange.svg';
import { ReactComponent as ManagerToMemberV1Purple } from '../Assets/Images/Profile/Manager_to_member_v1_purple.svg';
import { ReactComponent as ManagerToMemberV2BlackAndBrown } from '../Assets/Images/Profile/Manager_to_member_v2_black_and_brown.svg';
import { ReactComponent as ManagerToMemberV2Brown } from '../Assets/Images/Profile/Manager_to_member_v2_brown.svg';
import { ReactComponent as ManagerToMemberV2Cyan } from '../Assets/Images/Profile/Manager_to_member_v2_cyan.svg';
import { ReactComponent as ManagerToMemberV2Gold } from '../Assets/Images/Profile/Manager_to_member_v2_gold.svg';
import { ReactComponent as ManagerToMemberV2Green } from '../Assets/Images/Profile/Manager_to_member_v2_green.svg';
import { ReactComponent as ManagerToMemberV2Heliotrope } from '../Assets/Images/Profile/Manager_to_member_v2_Heliotrope.svg';
import { ReactComponent as ManagerToMemberV2Orange } from '../Assets/Images/Profile/Manager_to_member_v2_orange.svg';
import { ReactComponent as ManagerToMemberV2Purple } from '../Assets/Images/Profile/Manager_to_member_v2_purple.svg';
import { ReactComponent as MemberToManagerBlackAndBrown } from '../Assets/Images/Profile/Member_to_manager_black_and_brown.svg';
import { ReactComponent as MemberToManagerBrown } from '../Assets/Images/Profile/Member_to_manager_brown.svg';
import { ReactComponent as MemberToManagerGold } from '../Assets/Images/Profile/Member_to_manager_gold.svg';
import { ReactComponent as MemberToManagerPurple } from '../Assets/Images/Profile/Member_to_manager_purple.svg';
import { ReactComponent as PeerBlackAndBrown } from '../Assets/Images/Profile/Peer_black_and_brown.svg';
import { ReactComponent as PeerBrown } from '../Assets/Images/Profile/Peer_brown.svg';
import { ReactComponent as PeerGold } from '../Assets/Images/Profile/Peer_gold.svg';
import { ReactComponent as PeerPurple } from '../Assets/Images/Profile/Peer_purple.svg';
import { ReactComponent as SalesBlackAndBrown } from '../Assets/Images/Profile/Sales_black_and_brown.svg';
import { ReactComponent as SalesBlack } from '../Assets/Images/Profile/Sales_black.svg';
import { ReactComponent as SalesBrown } from '../Assets/Images/Profile/Sales_brown.svg';
import { ReactComponent as SalesCyan } from '../Assets/Images/Profile/Sales_cyan.svg';
import { ReactComponent as SalesGold } from '../Assets/Images/Profile/Sales_gold.svg';
import { ReactComponent as SalesGreen } from '../Assets/Images/Profile/Sales_green.svg';
import { ReactComponent as SalesHeliotrope } from '../Assets/Images/Profile/Sales_Heliotrope.svg';
import { ReactComponent as SalesOrange } from '../Assets/Images/Profile/Sales_orange.svg';
import { ReactComponent as SalesPurple } from '../Assets/Images/Profile/Sales_purple.svg';
import { ReactComponent as Coaching } from '../Assets/Images/Profile/coaching.svg';
import { ReactComponent as Comunication } from '../Assets/Images/Profile/comunication.svg';
import { ReactComponent as ManagerToMemberBlue } from '../Assets/Images/Profile/Manager_to_member_blue.svg';
import { ReactComponent as ManagerToMemberGreen } from '../Assets/Images/Profile/Manager_to_member_green.svg';
import { ReactComponent as ManagerToMemberOrange } from '../Assets/Images/Profile/Manager_to_member_orange.svg';
import { ReactComponent as PeerProfile } from '../Assets/Images/Profile/peerProfile.svg';
import { ReactComponent as ManagerToMemberV1Cyan } from '../Assets/Images/Profile/PNG_Manager to member v1_cyan.svg';
import { ReactComponent as ManagerToMemberV1Heliotrope } from '../Assets/Images/Profile/PNG_Manager to member v1_Heliotrope.svg';
import { ReactComponent as MemberToManagerCyan } from '../Assets/Images/Profile/PNG_Member to manager_cyan.svg';
import { ReactComponent as MemberToManagerGreen } from '../Assets/Images/Profile/PNG_Member to manager_green.svg';
import { ReactComponent as MemberToManagerHeliotrope } from '../Assets/Images/Profile/PNG_Member to manager_Heliotrope.svg';
import { ReactComponent as MemberToManagerOrange } from '../Assets/Images/Profile/PNG_Member to manager_orange.svg';
import { ReactComponent as PeerCyan } from '../Assets/Images/Profile/PNG_Peer_cyan.svg';
import { ReactComponent as PeerGreen } from '../Assets/Images/Profile/PNG_Peer_green.svg';
import { ReactComponent as PeerHeliotrope } from '../Assets/Images/Profile/PNG_Peer_Heliotrope.svg';
import { ReactComponent as PeerOrange } from '../Assets/Images/Profile/PNG_Peer_orange.svg';
import { ReactComponent as Sales } from '../Assets/Images/Profile/sales.svg';
import { ReactComponent as Session1 } from '../Assets/Images/Profile/session1.svg';
import { ReactComponent as Session2 } from '../Assets/Images/Profile/session2.svg';
import { ReactComponent as Session3 } from '../Assets/Images/Profile/session3.svg';
import { ReactComponent as TeamMemberProfile } from '../Assets/Images/Profile/teamMemberProfile.svg';
import { ReactComponent as TestCategory } from '../Assets/Images/Profile/testcategory.svg';
import { ReactComponent as TeamMemberToManagerPurple } from '../Assets/Images/Profile/Team_Member_to_manager_purple.svg';
import { ReactComponent as ManagerProfile } from '../Assets/Images/Profile/managerProfile.svg';

const svgIcons = new Map([
	///// Badges
	['Agree_and_build', AgreeAndBuildIcon],
	['Argumentation', ArgumentationIcon],
	['Brainstorm_strategic_solutions', BrainstormStrategicSolutions],
	['Clarify_actions_and_accountability', ClarifyActionsAndAccountability],
	['Clarity', Clarity],
	['Creating_Shared_Meaning', CreatingSharedMeaning],
	['Establishing_Resolution', EstablishingResolution],
	['Establishing_Safety', EstablishingSafety],
	['Framing', Framing],
	['Investigate_what’s_important', InvestigateWhatsImportant],
	['Invite_feelings', InviteFeelings],
	['Invite_others’_views', InviteOthersViews],
	['Logic', Logic],
	['Persuasion-04', Persuasion04],
	['Persuasion-05', Persuasion05],
	['Persuasion-17', Persuasion17],
	['Responsiveness', Responsiveness],
	['Restoring_Safety', RestoringSafety],
	['Seek_a_higher_purpose', SeekAHigherPurpose],
	['Step_out_of_the_conversation', StepOutOfTheConversation],
	['Summarize_their_perspective', SummarizeTheirPerspective],
	['Take_Accountability', TakeAccountability],
	['Understanding', Understanding],

	///// Profile

	// Client
	['Client_black_and_brown', ClientBlackAndBrown],
	['Client_brown', ClientBrown],
	['Client_cyan', ClientCyan],
	['Client_gold', ClientGold],
	['Client_green', ClientGreen],
	['Client_Heliotrope', ClientHeliotrope],
	['Client_orange', ClientOrange],
	['Client_purple', ClientPurple],
	['clientProfile', ClientProfile],

	// Manager to member

	['Manager_to_member_blue', ManagerToMemberBlue],
	['Manager_to_member_green', ManagerToMemberGreen],
	['Manager_to_member_orange', ManagerToMemberOrange],

	['Manager_to_member_v1_black_and_brown', ManagerToMemberV1BlackAndBrown],
	['Manager_to_member_v1_brown', ManagerToMemberV1Brown],
	['Manager_to_member v1_gold', ManagerToMemberV1Gold],
	['Manager_to_member_v1_green', ManagerToMemberV1Green],
	['Manager_to_member_v1_orange', ManagerToMemberV1Orange],
	['Manager_to_member_v1_purple', ManagerToMemberV1Purple],
	['PNG_Manager to member v1_cyan', ManagerToMemberV1Cyan],
	['PNG_Manager to member v1_Heliotrope', ManagerToMemberV1Heliotrope],

	['Manager_to_member_v2_black_and_brown', ManagerToMemberV2BlackAndBrown],
	['Manager_to_member_v2_brown', ManagerToMemberV2Brown],
	['Manager_to_member_v2_cyan', ManagerToMemberV2Cyan],
	['Manager_to_member_v2_gold', ManagerToMemberV2Gold],
	['Manager_to_member_v2_green', ManagerToMemberV2Green],
	['Manager_to_member_v2_Heliotrope', ManagerToMemberV2Heliotrope],
	['Manager_to_member_v2_orange', ManagerToMemberV2Orange],
	['Manager_to_member_v2_purple', ManagerToMemberV2Purple],

	// Member to manager
	['Member_to_manager_black_and_brown', MemberToManagerBlackAndBrown],
	['Member_to_manager_brown', MemberToManagerBrown],
	['Member_to_manager_gold', MemberToManagerGold],
	['Member_to_manager_purple', MemberToManagerPurple],
	['PNG_Member to manager_cyan', MemberToManagerCyan],
	['PNG_Member to manager_green', MemberToManagerGreen],
	['PNG_Member to manager_Heliotrope', MemberToManagerHeliotrope],
	['PNG_Member to manager_orange', MemberToManagerOrange],

	// Peer
	['Peer_black_and_brown', PeerBlackAndBrown],
	['Peer_brown', PeerBrown],
	['Peer_gold', PeerGold],
	['Peer_purple', PeerPurple],
	['PNG_Peer_cyan', PeerCyan],
	['PNG_Peer_green', PeerGreen],
	['PNG_Peer_Heliotrope', PeerHeliotrope],
	['PNG_Peer_orange', PeerOrange],
	['peerProfile', PeerProfile],

	// Sales
	['Sales_black_and_brown', SalesBlackAndBrown],
	['Sales_black', SalesBlack],
	['Sales_brown', SalesBrown],
	['Sales_cyan', SalesCyan],
	['Sales_gold', SalesGold],
	['Sales_green', SalesGreen],
	['Sales_Heliotrope', SalesHeliotrope],
	['Sales_orange', SalesOrange],
	['Sales_purple', SalesPurple],
	['sales', Sales],

	// Other
	['coaching', Coaching],
	['comunication', Comunication],
	['session1', Session1],
	['session2', Session2],
	['session3', Session3],
	['teamMemberProfile', TeamMemberProfile],
	['testcategory', TestCategory],
	['Team_Member_to_manager_purple', TeamMemberToManagerPurple],
	['managerProfile', ManagerProfile]
]);

export const getIcon = (name: string) => {
	// if (!svgIcons.has(name)) {
	// 	throw new Error(`Icon with name "${name}" does not exist.`);
	// }
	return svgIcons.get(name);
};
