import React, { useContext, useEffect, useRef, useState } from 'react';
// import ChatMessage from "./ChatMessage";

import { useSelector } from 'react-redux';
import PrepareInput from './PrepareInput';
import { ChannelE, ChatContext } from '../../../Contexts';
import { useChannel } from '../../../hooks/useChannel';
import { IChat } from '../../../types';
import { textToSpeech } from '../../../Helper/TextToSpeech';
import ChatMessage from '../../Chat/ChatMessage';
import getUser from '../../../Helper/getUser';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TypingDots from '../../Loader/TypingLoader/TypingDots';
const ChatBox = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const voiceStatus = useSelector((state: any) => state.voice.voiceStatus);
	const user = getUser();
	const {
		messages,
		recommendations,
		selectedConversationId,
		setMessages,
		setChatLoading,
		chatLoading,
		setConversations,
		conversations,
		audioQueue,
		isPlaying,
		setFeedbackContent,
		setRecommendations,
		setChatProcessing,
		setCoachSays,
		setAudioQueue,
		setPlaying
	} = useContext(ChatContext);
	const { roleName } = useSelector((state: any) => state?.role);
	const [isOpen, setIsOpen] = useState(false);
	const { sendMessage } = useChannel(ChannelE.simulationPrepare);

	const messagRef = useRef<HTMLDivElement>(null);
	useChannel(ChannelE.simulationPrepare, eventMessage => {
		const { message, conversationId, allMessages, messageCompleted, audioChunk } = eventMessage;
		if (conversationId === selectedConversationId) {
			// Duplication checking
			let duplicateLine = false;
			if (message?.messageType === 'coach' && audioChunk && voiceStatus) {
				for (const item of audioQueue) {
					if (audioChunk == item) {
						duplicateLine = true;
					}
				}
				if (!duplicateLine) {
					textToSpeech(audioChunk, audioQueue, setAudioQueue, roleName?.voice_modal?.toLowerCase());
				}
			}
			if (message?.messageType === 'coach' && allMessages) {
				setChatLoading(false);
				setMessages(allMessages);
				const responseConversations = conversations.map(conversation =>
					conversation.conversationId === conversationId
						? {
								...conversation,
								messages: allMessages
							}
						: conversation
				);
				setConversations(responseConversations);
				if (messageCompleted) {
					if (Array.isArray(allMessages) && allMessages.length > 0) {
						const lastItem = allMessages[allMessages?.length - 1];
					}
					setChatLoading(false);
				}
			} else {
				setMessages((prevMessages = []) => {
					if (prevMessages[prevMessages.length - 1]?.messageId == message?.messageId) {
						return prevMessages;
					}
					return [...prevMessages, message];
				});
			}
			if (message?.messageType === 'coach' && messageCompleted) {
				setChatProcessing(false);
			}
		}
	});

	// Update every time isPlaying or AudioQueue is changed
	useEffect(() => {
		if (audioQueue.length > 0) {
			setCoachSays(true);
		} else {
			setCoachSays(false);
		}
		if (audioQueue[0] instanceof Audio && !isPlaying) {
			setPlaying(true);
			const audio = audioQueue[0];
			const playAudio = async () => {
				try {
					await audio.play();
				} catch {
					// The audio is not ready to be played, try again later
					setTimeout(playAudio, 100);
				}
			};
			playAudio();
			audio.addEventListener('ended', async () => {
				setAudioQueue(prevQueue => prevQueue.slice(1));
				setPlaying(false);
			});
		}
	}, [audioQueue, isPlaying]);

	useChannel(ChannelE.feedbackChannel, async eventMessage => {
		const { feedbackChat, conversationId } = eventMessage;
		if (feedbackChat && selectedConversationId === conversationId) {
			setFeedbackContent(feedbackChat);
		}
	});

	useChannel(ChannelE.recommendationChannel, eventMessage => {
		const { recommendationChat, conversationId } = eventMessage;
		if (recommendationChat && selectedConversationId === conversationId) {
			setRecommendations(recommendationChat);
		}
	});
	useEffect(() => {
		if (messagRef.current) {
			messagRef.current.scrollTop = messagRef.current.scrollHeight;
		}
		if (messages.length >= 19 && (messages.length - 19) % 4 === 0) {
			setIsOpen(true);
		}
	}, [messages, recommendations]);
	// useEffect(() => {
	//   if (Array.isArray(conversations) && conversations.length > 0) {
	//     setSelectedConversationId(conversations[0]?.conversationId);
	//     setMessages(conversations[0]?.messages);
	//   } else {
	//     setSelectedConversationId(undefined);
	//     setMessages([]);
	//   }
	// }, [conversations]);

	return (
		<div>
			<div className="message-wrapper" ref={messagRef}>
				{messages?.map((chat: IChat, index: number) => (
					<ChatMessage key={index} message={chat} index={index} sendMessage={sendMessage} />
				))}
				{chatLoading && <><TypingDots/></>}
				
			</div>
			{user?.role === 'client' && <PrepareInput />}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				autoHideDuration={5000}
			>
				<SnackbarContent
					message="You can proceed to simulation by clicking on the NEXT button."
					action={
						<IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
							<CloseIcon fontSize="small" />
						</IconButton>
					}
					sx={{
						backgroundColor: 'white',
						color: 'black',
						border: '1px solid blue'
					}}
				/>
			</Snackbar>
		</div>
	);
};

export default ChatBox;
