import React, { useContext, useEffect, useRef } from 'react';
// import ChatMessage from "./ChatMessage";

import { useDispatch, useSelector } from 'react-redux';
import { ChannelE, ChatContext } from '../../../Contexts';
import { useChannel } from '../../../hooks/useChannel';
import { IChat } from '../../../types';
import { textToSpeech } from '../../../Helper/TextToSpeech';
import ChatSimulationMessage from '../../Chat/ChatSimulationMessage';
import SimulationInput from './SimulationInput';
import { getConversationsOfUser, getRecommendationsOfChat } from '../../../Helper';
import getUser from '../../../Helper/getUser';
import { setLastConversation } from '../../../Helper/Redux/Action/userAction';
import TypingIndicator from '../../Loader/TypingLoader/TypingDots';

const SimulationBox = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const voiceStatus = useSelector((state: any) => state.voice.voiceStatus);
	// const selectedSimulationMessages = useSelector((state: any) => state.selectedCompletedSimulation.simulationMessages);
	// const selectedSimulationId = useSelector((state: any) => state.selectedCompletedSimulation.simulationId);
	const selectSimulationId = useSelector((state: any) => state.conversationId.conversationId);
	const dispatch = useDispatch();
	const conversationId = useSelector((state: any) => state.conversationId.conversationId);
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const user = getUser();

	useEffect(() => {
		getConversations();
	}, []);

	const getConversations = async () => {
		let userId;
		selectedUserId ? (userId = selectedUserId) : (userId = user?.id);
		const conversations = await getConversationsOfUser('simulation', userId);

		const conversation = conversations?.find(conversation => conversation.conversationId === conversationId);
		const lastClientMessage = conversation?.messages.reverse().find(message => message.messageType === 'client');

		setMessages((conversation?.messages.reverse() as IChat[]) || []);
		if (lastClientMessage?.messageId) {
			const listRecommendations = await getRecommendationsOfChat(
				'recommendation',
				userId,
				conversationId,
				lastClientMessage?.messageId
			);

			listRecommendations && setRecommendations(listRecommendations);
		}
	};

	const {
		messages,
		recommendations,
		selectedConversationId,
		setMessages,
		setChatLoading,
		chatLoading,
		setConversations,
		conversations,
		audioQueue,
		isPlaying,
		setFeedbackContent,
		setRecommendations,
		setChatProcessing,
		setCoachSays,
		setSimulationFeedbackRequire,
		setSelectedConversationId,
		setAudioQueue,
		setPlaying
	} = useContext(ChatContext);
	const messagRef = useRef<HTMLDivElement>(null);
	const { roleName } = useSelector((state: any) => state?.role);
	const lastconversationId = useSelector((state: any) => state.conversationId.conversationId);
	const lastConversation = useSelector((state: any) => state.lastCoversationMessage.lastConversation);

	useChannel(ChannelE.simulation, eventMessage => {
		const { message, conversationId, allMessages, messageCompleted, audioChunk } = eventMessage;

		if (conversationId === selectSimulationId) {
			if (message?.messageType === 'coach' && allMessages) {
				// Duplication checking
				let duplicateLine = false;
				if (message?.messageType === 'coach' && audioChunk && voiceStatus) {
					for (const item of audioQueue) {
						if (audioChunk == item) {
							duplicateLine = true;
						}
					}
					if (!duplicateLine) {
						textToSpeech(audioChunk, audioQueue, setAudioQueue, roleName?.voice_modal?.toLowerCase());
					}
				}
				setChatLoading(false);
				setMessages(allMessages);
				
				const responseConversations = conversations.map(conversation =>
					conversation.conversationId === conversationId
						? {
								...conversation,
								messages: allMessages
							}
						: conversation
				);
				setConversations(responseConversations);
				if (messageCompleted) {
					if (Array.isArray(allMessages) && allMessages.length > 0) {
						const lastItem = allMessages[allMessages?.length - 1];
					}
					setChatLoading(false);
				}
			} else {
				setMessages((prevMessages = []) => {
					if (prevMessages[prevMessages.length - 1]?.messageId == message?.messageId) {
						return prevMessages;
					}
					return [...prevMessages, message];
				});
				setSimulationFeedbackRequire(true);
			}
			if (message?.messageType === 'coach' && messageCompleted) {
				setChatProcessing(false);
			}
		}
	});

	// Update every time isPlaying or AudioQueue is changed
	useEffect(() => {
		if (audioQueue.length > 0) {
			setCoachSays(true);
		} else {
			setCoachSays(false);
		}
		if (audioQueue[0] instanceof Audio && !isPlaying) {
			setPlaying(true);
			const audio = audioQueue[0];
			const playAudio = async () => {
				try {
					await audio.play();
				} catch {
					// The audio is not ready to be played, try again later
					setTimeout(playAudio, 100);
				}
			};
			playAudio();
			audio.addEventListener('ended', async () => {
				setAudioQueue(prevQueue => prevQueue.slice(1));
				setPlaying(false);
			});
		}
	}, [audioQueue, isPlaying]);

	useChannel(ChannelE.feedbackChannel, async eventMessage => {
		const { feedbackChat, conversationId } = eventMessage;
		if (feedbackChat && (selectedConversationId || selectSimulationId) === conversationId) {
			setFeedbackContent(feedbackChat);
		}
	});

	useChannel(ChannelE.recommendationChannel, eventMessage => {
		const { recommendationChat, conversationId } = eventMessage;
		if (recommendationChat && (selectedConversationId || selectSimulationId) === conversationId) {
			setRecommendations(recommendationChat);
		}
	});
	useEffect(() => {
		if (messagRef.current) {
			messagRef.current.scrollTop = messagRef.current.scrollHeight;
		}
	}, [messages, recommendations]);

	// useEffect(() => {
	// 	if (selectedSimulationMessages !== null && selectedSimulationId !== null) {
	// 		setMessages(selectedSimulationMessages);
	// 		setSelectedConversationId(selectedSimulationId);
	// 		dispatch(setConversationId(selectedSimulationId));
	// 	}
	// }, []);

	useEffect(() => {
		if (messages.length > 0 && lastconversationId) {
			dispatch(setLastConversation(messages));
		}
		if (lastconversationId && messages.length === 0 && lastConversation) {
			setMessages(lastConversation);
			setSelectedConversationId(lastconversationId);
		}
	}, [lastconversationId, messages]);

	return (
		<div className="message-wrapper" ref={messagRef}>
			<div>
				{messages?.map((chat: IChat, index: number) => (
					<ChatSimulationMessage key={index} message={chat} index={index} />
				))}
				{chatLoading && <><TypingIndicator/></>}
			</div>
		</div>
	);
};

export default SimulationBox;
