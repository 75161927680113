import React from 'react';
import { ChatContext } from '../../../../Contexts';

import { useSelector } from 'react-redux';
// import { getToken } from '../../../../Helper/GetToken';
// import { getCurrentTimeStamp } from '../../../../Helper';
import { Box, Card, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import simulationIcon from '../../../../Assets/Images/simulation_icon_v2.png';
import preparationIcon from '../../../../Assets/Images/preparation_icon_v2.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const buttonStyles = {
	width: '250px',
	height: '172px',
	borderRadius: '8px',
	border: '2px solid #1C75BA',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',

	'&:hover': {
		border: '2px solid #1C75BA',
		backgroundColor: '#e1f2ff',
		color: '#FFFFFF'
	}
};

const SelectionStep = ({
	handleNext,
	firstInteractionFromCoach,
	setSelectionStep
}: {
	handleNext: (step: number) => void;
	firstInteractionFromCoach: () => void;
	setSelectionStep: (step: boolean) => void;
}) => {
	const { chatLoading } = React.useContext(ChatContext);
	// const [summaryText, setSummaryText] = React.useState('');
	const { roleName } = useSelector((state: any) => state?.role);
	// const [open, setOpen] = React.useState(false);
	const [loadingIframe, setLoadingIframe] = React.useState<any>([]);
	const isSmallScreen = useMediaQuery('(max-width: 600px)');

	return (
		<Box sx={{ width: '100%', maxWidth: 'auto', paddingX: '20px', mt: '-60px' }}>
			<Typography sx={{ mb: 2, cursor: 'pointer' }} color={'#1C75BA'} onClick={() => setSelectionStep(false)}>
				<ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} /> Back
			</Typography>
			<Typography sx={{ marginBottom: '10px', fontWeight: '700', fontSize: '28px' }}>
				Please choose your next step:
			</Typography>
			You can have a preparation session with a coach regarding the simulation or Start the simulation
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', my: '20px' }}>
				<Box
					onClick={async () => {
						if (!chatLoading) {
							handleNext(1);
							await firstInteractionFromCoach();
						}
					}}
					sx={{ ...buttonStyles, cursor: chatLoading ? 'not-allowed' : 'pointer' }}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<img width={80} height={80} src={preparationIcon} alt="Preparation Icon" />
						<Typography sx={{ fontSize: '20px', mt: '8px', fontWeight: '700', color: 'black' }}>Preparation</Typography>
					</div>
				</Box>
				<Box
					onClick={() => !chatLoading && handleNext(2)}
					sx={{ ...buttonStyles, cursor: chatLoading ? 'not-allowed' : 'pointer' }}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<img width={80} height={80} src={simulationIcon} alt="Simulation Icon" />
						<Typography sx={{ fontSize: '20px', mt: '8px', fontWeight: '700', color: 'black', textAlign: 'center' }}>
							Simulation
						</Typography>
					</div>
				</Box>
			</Box>
			{/* <Typography sx={{ marginBottom: '10px', fontWeight: '600', fontSize: '20px' }}>Simulation Summary</Typography> */}
			{/* {chatLoading ? (
				'Loading...'
			) : (
				<div style={{ width: '60%' }}>
					{summaryText?.length > 0 && (
						<div
							dangerouslySetInnerHTML={{
								__html: summaryText
							}}
						/>
					)}
				</div>
			)} */}
			{roleName?.video_url && (
				<Box
					sx={{
						// width: isSmallScreen ? '100%' : 550, // Full width for small screens
						// height: isSmallScreen ? 'auto' : 350, // Adjust height for small screens
						marginBottom: '10px',
						overflow: 'auto'
					}}
				>
					{/* Title */}
					<Typography
						variant="h6"
						sx={{
							width: '100%',
							color: 'black',
							textAlign: 'left',
							marginBottom: '10px',
							fontWeight: 'bold',
							fontSize: isSmallScreen ? '1rem' : '1.25rem'
						}}
					>
						Introduction
					</Typography>

					{/* Video Content */}
					<Card
						elevation={0}
						sx={{
							width: '100%',
							height: isSmallScreen ? '200px' : '100%',
							position: 'relative'
							// display: 'flex',
							// flexWrap: 'wrap',
							// gap: '10px',
						}}
					>
						<>
							{/* Video */}
							{roleName.video_url?.length &&
								roleName.video_url?.split(',')?.length > 0 &&
								roleName.video_url.split(',')?.map((video: any, index: any) => {
									if (video.includes('scenario_videos'))
										return (
											<>
												{/* <iframe
													key={index}
													// width="100%"
													height="100%"
													src={video}
													onLoad={() => setLoadingIframe((prev: any) => [...prev, index])}
													onError={() => setLoadingIframe((prev: any) => [...prev, index])}
													loading="lazy"
													style={{
														border: 'none',
														display: loadingIframe?.includes(index) ? 'hidden' : 'block',
														maxHeight: '100%',
														maxWidth: '100%',
														marginTop: '10px'
													}}
												/> */}
												{/* {!loadingIframe?.includes(index) && <LinearProgress style={{ width: '300px' }} />} */}
												<video controls style={{ width: '450px', display: 'block', marginTop: '10px' }} src={video}>
													Your browser does not support to load the video.
												</video>
											</>
										);
								})}
						</>
					</Card>
				</Box>
			)}
			{roleName?.description?.length > 0 && (
				<Typography sx={{ marginBottom: '10px', fontWeight: '600', fontSize: '20px' }}>Scenario Description</Typography>
			)}
			<div style={{ width: '60%', marginBottom: '50px' }}>
				{roleName?.description?.length > 0 && (
					<div
						dangerouslySetInnerHTML={{
							__html: roleName?.description
						}}
					/>
				)}
			</div>
		</Box>
	);
};

export default SelectionStep;
