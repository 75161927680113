
import React, { useContext } from 'react';
import shieldIcon from '../../Assets/Images/Badges/image 9.png';
import inteIcon from '../../Assets/Images/Badges/image 10.png';
import cloudIcon from '../../Assets/Images/Badges/image 11.png';
import refreshIcon from '../../Assets/Images/Badges/image 12.png';
import enlargeIcon from '../../Assets/Images/Badges/image 13.png';
import { useDispatch, useSelector } from 'react-redux';
import { setBadgeSimulation } from '../../Helper/Redux/Action/userAction';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../Contexts';
import { Box, Typography, Tooltip, SvgIcon } from '@mui/material';
import { getIcon } from '../../utils/svgIcons';

const Badges = () => {
	const dispatch = useDispatch();
	// const allIcons = [shieldIcon, inteIcon, cloudIcon, refreshIcon, enlargeIcon];
	const level = useSelector((state: any) => state.level) || 0;
	const simulations = useSelector((state: any) => state.cohortSimulation.simulations) || [];
	const navigate = useNavigate();
	const { isTheLastMessage, isConnected } = useContext(ChatContext);
	const disabled = isTheLastMessage || isConnected;
	const progressHandler = (id: any) => {
		dispatch(setBadgeSimulation(id));
		navigate('/progress');
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
			<Typography sx={{ fontFamily: 'IBM Plex Sans !important', fontSize: '16px !important' }}>
				Simulation Levels Accomplished:
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '0px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
				{simulations.map(
					(
						simulation: { scenario_name: any; scenario_id: any; badge: any; isPassed: any },
						index: React.Key | null | undefined
					) => {
						// Get the scenario details from the current simulation item
						const { scenario_name, scenario_id, badge, isPassed } = simulation;
						return (
							<Tooltip key={index} title={scenario_name} arrow>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										cursor: 'pointer'
									}}
									onClick={() => {
										if (isPassed && !disabled) {
											progressHandler(scenario_id); // Only call if isPassed
										}
									}}>
									{(() => {
										const icon = getIcon(badge?.name);
										if (icon) {
											return (
												<SvgIcon
													component={icon}
													viewBox="0 0 384 512"
													sx={{
														fontSize: '3.2rem',
														filter: isPassed ? 'none' : 'grayscale(100%)',
														opacity: isPassed ? 1 : 0.5,
														marginRight: '10px',
														width: '1.1em'
													}}
												/>
											);
										} else {
											return (
												<SvgIcon
													component="svg"
													viewBox="0 0 24 24"
													sx={{
														fontSize: '3rem',
														filter: isPassed ? 'none' : 'grayscale(100%)',
														opacity: isPassed ? 1 : 0.5,
														margin: '10px'
													}}>
													<circle cx="12" cy="12" r="10" fill="gray" />
												</SvgIcon>
											);
										}
									})()}
								</Box>
							</Tooltip>
						);
					}
				)}
			</Box>
		</Box>
	);
};

export default Badges;
