import {
	Box,
	Button,
	CircularProgress,
	Modal,
	Paper,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SVG_Badge } from '../../../Assets/Images/Badges/SVG_Agree_and_build.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';
import MemberTable, { CategoryType } from '../Member/MemberTable';
import getUser from '../../../Helper/getUser';
import { getIcon } from '../../../utils/svgIcons';

const CategoryTable = () => {
	const [tableData, setTableData] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [categoryData, setCategoryData] = useState<CategoryType>({
		scenario_name: '',
		parent_id: null,
		category: '',
		picture: { id: '', name: '', title: '' },
		description: '',
		scenario_id: 0,
		role_name: '',
		video_url: null,
		enable_voice_mode: false,
		voice_modal: null,
		badge: { id: '', name: '', title: '' },
		summary: null
	});
	const [parent_id, setParent_id] = useState(0);
	const [showMember, setShowMember] = useState(false);
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();
	const { unhandleToken } = useHandleToken();

	useEffect(() => {
		if (isRefresh || limit || page) {
			getScenarios();
			getFile();
		}
	}, [isRefresh, limit, page]);

	const getScenarios = async () => {
		setLoading(true);
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				params: { page, limit, pagination: true },
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setTableData(res.data.scenarios);
					setTotal(res.data.pagination.total);
					setIsRefresh(false);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getFile = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allfile`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res?.data?.success) {
					setImageData(res?.data?.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const deleteHandler = async (id: number) => {
		await axios
			.delete(`${baseUrl}/api/scenario/deleteScenario/${id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(() => {
				setIsRefresh(!isRefresh);
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const editHandler = (data: {
		scenario_name: string;
		parent_id: any;
		category: string;
		picture: { id: string; name: string; title: string };
		description: string;
		scenario_id: number;
		role_name: string;
		video_url?: any;
		voice_modal?: any;
		badge?: { id: string; name: string; title: string };
		summary: string | null;
	}) => {
		setCategoryData({
			scenario_name: data.scenario_name || '',
			parent_id: data.parent_id ?? null,
			category: data.category || '',
			picture: data.picture || { id: '', name: '', title: '' },
			description: data.description || '',
			scenario_id: data.scenario_id || 0,
			role_name: data.role_name || '',
			video_url: data.video_url ?? null,
			voice_modal: data.voice_modal ?? null,
			badge: data.badge || { id: '', name: '', title: '' },
			summary: data?.summary || null
		});
		setEditOpen(true); // Open the Edit Category modal when edit button is clicked
	};

	const memberHandler = (id: number) => {
		setParent_id(id);
		setShowMember(true);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};

	return (
		<>
			{showMember ? (
				<MemberTable imageData={imageData} parent_id={parent_id} setShowMember={setShowMember} />
			) : (
				<Box sx={{ padding: '0 10px' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
						<Box></Box>
						<Box>
							<Typography variant="h6">Category</Typography>
						</Box>
						<Button
							startIcon={<AddIcon />}
							onClick={() => setOpen(true)} // Open Create Category modal
							disabled={user?.role === 'editor'}>
							Add
						</Button>
					</Box>
					<Box>
						{loading ? (
							<CircularProgress />
						) : (
							<TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
								<Table>
									<TableHead sx={{ background: '#CFE9FD', border: '1px solid rgba(224, 224, 224, 1)' }}>
										<TableRow sx={{ textWrap: 'nowrap' }}>
											<TableCell align="left">Scenario Id</TableCell>
											<TableCell align="left">Scenario Name</TableCell>
											<TableCell align="left">Category</TableCell>
											<TableCell align="left">Description</TableCell>
											<TableCell align="left">Role</TableCell>
											<TableCell align="left">Profile</TableCell>
											<TableCell align="left">Badge</TableCell>
											<TableCell align="left">Edit</TableCell>
											<TableCell align="left">Delete</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{Array.isArray(tableData) &&
											tableData.length > 0 &&
											tableData.map((items: CategoryType) => {
												return (
													<TableRow
														sx={{ border: '1px solid rgba(224, 224, 224, 1)', cursor: 'pointer' }}
														key={items.scenario_id}>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_id}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_name}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.category}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.description}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.role_name || '-'}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.picture.name &&
																(() => {
																	const icon = getIcon(items?.picture.name);
																	return icon ? (
																		<SvgIcon
																			component={icon}
																			viewBox="0 0 384 512"
																			sx={{ fontSize: '2rem', width: '1.1em' }}
																		/>
																	) : null;
																})()}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.badge?.name &&
																(() => {
																	const icon = getIcon(items.badge.name);
																	return icon ? (
																		<SvgIcon
																			component={icon}
																			viewBox="0 0 384 512"
																			sx={{ fontSize: '2rem', width: '1.1em' }}
																		/>
																	) : null;
																})()}
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																color="secondary"
																size="small"
																startIcon={<EditIcon />}
																onClick={() => editHandler(items)}>
																Edit
															</Button>
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																size="small"
																startIcon={<DeleteIcon />}
																onClick={() => deleteHandler(items.scenario_id)}>
																Delete
															</Button>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={total}
									page={page - 1}
									onPageChange={handleChangePage}
									rowsPerPage={limit}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[5, 10, 20]}
								/>
							</TableContainer>
						)}
					</Box>
				</Box>
			)}

			{/* Create Category Modal */}
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<div>
					<CreateCategory imageData={imageData} setIsRefresh={setIsRefresh} setOpen={setOpen} />
				</div>
			</Modal>

			{/* Edit Category Modal */}
			<Modal
				open={editOpen}
				onClose={() => setEditOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<div>
					<EditCategory
						categoryData={categoryData}
						imageData={imageData}
						setIsRefresh={setIsRefresh}
						setEditOpen={setEditOpen}
					/>
				</div>
			</Modal>
		</>
	);
};

export default CategoryTable;
