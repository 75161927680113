import React, { useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
	Alert,
	Autocomplete,
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};
interface CreateCoachStudentProps {
	setOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	selectedRole: unknown;
	isRefresh: boolean;
}
export const AddCoachStudent: React.FC<CreateCoachStudentProps> = ({
	setOpen,
	setIsRefresh,
	selectedRole,
	isRefresh
}) => {
	const [data, setData] = useState({
		userId: '',
		cohortId: ''
	});
	const [userData, setUserData] = useState([]);
	const [cohortData, setCohortData] = useState([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		severity: 'success' // can be 'success', 'error', 'warning', or 'info'
	});
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getUser();
		getCohort();
	}, []);

	const getUser = async () => {
		await axios
			.post(
				`${baseUrl}/api/admin/getuserByRole`,
				{ role: selectedRole },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(res => {
				if (res.data.success) {
					setUserData(res.data.users);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const getCohort = async () => {
		await axios
			.get(`${baseUrl}/api/admin/getAllCohorts`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setCohortData(res.data.cohorts);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		try {
			await axios
				.patch(
					`${baseUrl}/api/admin/updateUserCohort/${data.userId}`,
					{ cohortId: data.cohortId },
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(res => {
					if (res.data.success) {
						setOpen(false);
						setIsRefresh(!isRefresh);
					}
				})
				.catch(err => {
					// Extracting the error message from the response
					const errorMessage = err.response?.data?.message || 'An error occurred';
					// Show the error in a Snackbar
					setSnackbar({
						open: true,
						message: errorMessage,
						severity: 'error'
					});
				});
		} catch (error: any) {
			// Handle any additional unexpected errors
			console.log(error);
			const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
			setSnackbar({
				open: true,
				message: errorMessage,
				severity: 'error'
			});
			unhandleToken(e);
		}
	};

	const handleSnackbarClose = () => {
		setSnackbar({ ...snackbar, open: false });
	};
	return (
		<>
			<Box sx={style}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
					<Typography variant="h6">Assign Cohort</Typography>
					<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setOpen(false)}>
						<HighlightOffOutlinedIcon />
					</Button>
				</Box>
				{userData.length === 0 || cohortData.length === 0 ? (
					<Typography>User or cohort data not available yet, please wait...</Typography>
				) : (
					<>
						<form onSubmit={submitHandler}>
							<FormControl fullWidth sx={{ gap: 2 }}>
								<Autocomplete
									id="user-autocomplete"
									options={userData}
									getOptionLabel={(option: { firebase_id: string; first_name: string; last_name: string }) =>
										`${option.first_name}${option.last_name ? ' ' + option.last_name : ''}`
									}
									onChange={(event, value) => setData({ ...data, userId: value ? value.firebase_id : '' })}
									renderInput={params => (
										<TextField {...params} label="Select User" placeholder="Select User" fullWidth required />
									)}
									isOptionEqualToValue={(option, value) => option.firebase_id === value.firebase_id}
								/>
								<Autocomplete
									id="cohort-autocomplete"
									options={cohortData}
									getOptionLabel={(option: { id: number; name: string }) => option.name}
									onChange={(event, value) => setData({ ...data, cohortId: value ? value.id.toString() : '' })}
									renderInput={params => (
										<TextField {...params} label="Select Cohort" placeholder="Select Cohort" fullWidth required />
									)}
									isOptionEqualToValue={(option, value) => option.id === value.id}
								/>
								<Box>
									<Button type="submit">Submit</Button>
								</Box>
							</FormControl>
						</form>
						<Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
							<Alert onClose={handleSnackbarClose} severity="error">
								{snackbar.message}
							</Alert>
						</Snackbar>
					</>
				)}
			</Box>
		</>
	);
};
