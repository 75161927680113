import {
	Box,
	Button,
	IconButton,
	Snackbar,
	SnackbarCloseReason,
	styled,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../Helper/GetToken';
import getUser from '../../Helper/getUser';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedUser } from '../../Helper/Redux/Action/userAction';
import ExcelJS from 'exceljs';
import CloseIcon from '@mui/icons-material/Close';
import { ICohort } from '../../types/cohort';
interface Scenario {
	scenario_id: number;
	scenario_name: string;
	role_name: string | null;
	video_url: string | null;
	category: string;
	description: string;
	parent_id: number | null;
	voice_modal: string | null;
}

interface Cohort {
	id: number;
	name: string;
	start_date: string | null;
	end_date: string | null;
	created_at: string;
	deleted_at: string | null;
	deleted_by: number | null;
	created_by: number;
}

interface StatisticsData {
	id: number;
	time_spended: number;
	average_session_score: number;
	scenario: Scenario;
	cohort: Cohort;
	highest_score: number;
	session: number;
	levelPassed?: boolean;
}

interface User {
	id: number;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name: string;
	created_at: string;
	deleted_at: string | null;
}

interface Statistics {
	id: number;
	name: string;
	start_date: string;
	sessions: number;
	total_time: number;
	coach_total_time: number;
	average_score: number;
	communication: number | null;
	coaching: number | null;
	total_simulation: number;
	statisticsdata: StatisticsData[];
	user: User;
	Strengths: string;
	Weaknesses: string;
}

const SummaryTable = () => {
	const [statisticData, setStatisticData] = useState<Statistics[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [rows, setRows] = useState<(string | number)[][]>([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();

	const [selectedCohortId, setSelectedCohortId] = useState<string | number>(0);
	const [cohorts, setCohorts] = useState<ICohort[]>([]);

	const StyledLink = styled(Link)({
		textDecoration: 'none',
		color: 'inherit'
	});
	const [open, setOpen] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		getStatistic();
		getAllCohorts();
	}, []);

	useEffect(() => {
		if (cohorts.length > 0) {
			setSelectedCohortId(cohorts[0].id);
		}
	}, [cohorts]);

	const getAllCohorts = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/coachCohort`, {
				headers: {
					Authorization: token
				}
			});
			setCohorts(response.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getStatistic = async () => {
		if (user.id) {
			try {
				const res = await axios.get(`${baseUrl}/getstatistics/${user.id}`, {
					headers: {
						Authorization: token
					}
				});
				if (res.data) {
					setStatisticData(res.data);
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	const filterUsersBySelectedCohort = (data: any[], cohortId: number | string) => {
		return data
			.map(user => {
				const filteredStatisticsData = user.statisticsdata.filter(
					(stat: { cohort: { id: number } | null }) => stat.cohort && stat.cohort.id === cohortId
				);

				if (filteredStatisticsData.length === 0) {
					return null;
				}

				return { ...user, statisticsdata: filteredStatisticsData };
			})
			.filter(Boolean);
	};

	useEffect(() => {
		if (statisticData.length > 0) {
			const filtered = filterUsersBySelectedCohort(statisticData, selectedCohortId);

			const allScenarios = getAllScenarios(filtered);
			setHeaders(generateTableHeaders(allScenarios));
			setRows(generateTableRows(filtered, allScenarios));
		}
	}, [statisticData, selectedCohortId]);

	console.log(rows);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedCohortId(newValue);
	};

	const scenarioIds: number[] = [];

	// Function to get all unique scenario names and IDs
	const getAllScenarios = (data: Statistics[]): Set<string> => {
		const scenarios = new Set<string>();

		data.forEach(stat => {
			stat.statisticsdata.forEach(statData => {
				if (!scenarioIds.includes(statData.scenario.scenario_id)) {
					scenarioIds.push(statData.scenario.scenario_id);
				}
				scenarios.add(statData.scenario.scenario_name);
			});
		});

		return scenarios;
	};

	// Function to generate table headers
	const generateTableHeaders = (allScenarios: Set<string>): string[] => {
		const headers = ['Name'];

		return headers.concat(Array.from(allScenarios).flatMap(scenario => [`${scenario}`])).concat(['Total Sessions']);
	};

	// Function to generate table rows
	const generateTableRows = (data: Statistics[], allScenarios: Set<string>): (string | number)[][] => {
		return data.map(stat => {
			const row: any[] = [];
			row.push(stat.name);

			const scenarioData: {
				[key: string]: {
					session: number | string;
					levelPassed: boolean;
				};
			} = {};

			Array.from(allScenarios).forEach(scenario => {
				scenarioData[scenario] = {
					session: '0',
					levelPassed: false
				};
			});

			// Populate scenario data with actual values where available
			stat.statisticsdata.forEach(statData => {
				const scenario = statData?.scenario.scenario_name;
				scenarioData[scenario] = {
					session: statData?.session,
					levelPassed: statData?.levelPassed ?? false
				};
			});

			let totalSessions = 0;
			Object.keys(scenarioData).forEach(scenario => {
				totalSessions = totalSessions + +scenarioData[scenario]?.session;
				row.push(scenarioData[scenario]);
			});

			row.push(totalSessions);
			return row;
		});
	};

	const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	const action = (
		<React.Fragment>
			{/* <Button color="secondary" size="small" onClick={handleClose}>
				UNDO
			</Button> */}
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);
	return (
		<div>
			<Box
				sx={{
					marginBottom: '10px',
					borderBottom: '1px solid #e0e0e0',
					padding: { xs: '5px', sm: '5px' },
					backgroundColor: 'background.paper'
				}}>
				<Tabs
					value={selectedCohortId}
					onChange={handleTabChange}
					aria-label="Cohort tabs"
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						'& .MuiTab-root': {
							textTransform: 'none',
							fontWeight: 'normal',
							minWidth: '80px',
							padding: { xs: '8px 16px', sm: '12px 20px' }
						},
						'& .MuiTabScrollButton-root': {
							color: 'text.secondary'
						},
						'& .Mui-selected': {
							color: 'primary.main'
						},
						'& .MuiTabs-indicator': {
							backgroundColor: 'primary.main'
						}
					}}>
					{cohorts.map(cohort => (
						<Tab
							key={cohort.id}
							label={cohort.name}
							value={cohort.id}
							id={`tab-${cohort.id}`}
							aria-controls={`tabpanel-${cohort.id}`}
						/>
					))}
				</Tabs>
			</Box>
			{rows.length ? (
				<Box>
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#cfe9fd52',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}>
								<TableRow>
									{headers.map(header => (
										<TableCell align="left" key={header} style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
											{header}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, rowIndex) => (
									<TableRow key={rowIndex}>
										{row.map((cell: any, cellIndex) => (
											<TableCell
												align="left"
												key={cellIndex}
												style={{
													border: '1px solid rgba(224, 224, 224, 1)',
													backgroundColor: cell?.levelPassed ? 'green' : 'transparent',
													color: cell?.levelPassed ? 'white' : 'black'
												}}>
												{typeof cell == 'object' ? cell?.session : cell}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				'No Data Found'
			)}
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={notificationMessage}
				action={action}
			/>
		</div>
	);
};

export default SummaryTable;
