const initialSelectedSimulationState = {
	selectedSimulation: null
};

const initialSelectedSimulationIdState = {
	simulationId: null
};

export const selectedSimulationReducer = (
	state = initialSelectedSimulationState,
	action: { type: any; payload: any }
) => {
	switch (action.type) {
		case 'SET_SELECTED_SIMULATION':
			return {
				...state,
				selectedSimulation: action.payload
			};

		case 'CLEAR_SELECTED_SIMULATION':
			return {
				...state,
				selectedSimulation: null
			};

		default:
			return state;
	}
};

export const selectedSimulationIdReducer = (state = initialSelectedSimulationIdState, action: any) => {
	switch (action.type) {
		case 'SET_SIMULATION_ID':
			return {
				simulationId: action.payload
			};
		case 'CLEAR_SIMULATION_ID':
			return {
				simulationId: null
			};
		default:
			return state;
	}
};
