import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Box,
  Tab,
  Tabs
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

interface DataRow {
  fileDate: string;
  score: number;
  scenario: {scenario_name:string};
}

interface ScenarioData {
  scenario_id: number;
  scenario_name: string;
}

const ConversationEvaluation = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allScenario, setAllScenario] = useState<ScenarioData[]>([]);
  const [selectedScenarioId, setSelectedScenarioId] = useState<number | undefined>(undefined);

  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const token = getToken();
  const { unhandleToken } = useHandleToken();

  // Fetch scenarios once when the component mounts
  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/scenario/scenarios`, {
          headers: { Authorization: token }
        });
        setAllScenario(response.data);
        // setSelectedScenarioId(response.data[0]?.scenario_id); // Set default scenario ID
      } catch (error) {
        console.error('Error fetching scenarios:', error);
        unhandleToken(error);
      }
    };

    fetchScenarios();
  }, []);

  // Fetch evaluation data based on selectedScenarioId, page, and rowsPerPage
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/admin/getEvaluationReport`, {
          params: {
            page: page + 1, // API expects 1-based index for pagination
            limit: rowsPerPage,
            scenario_id: selectedScenarioId // Send selected scenario ID for filtering
          },
          headers: { Authorization: token }
        });

        setData(response.data.data);
        setTotal(response.data.pagination.total);
      } catch (error) {
        console.error('Error fetching data:', error);
        unhandleToken(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedScenarioId, page, rowsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page is changed
  };

  const handleTabChange = (event: React.SyntheticEvent, newScenarioId: number) => {
    setSelectedScenarioId(newScenarioId);
    setPage(0)
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        fontFamily: 'IBM Plex Sans'
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <Tabs
              value={selectedScenarioId}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Scenario Tabs"
              textColor="primary"
              indicatorColor="primary"
            >
              {allScenario?.map((item) => (
                <Tab
                  key={item.scenario_id}
                  label={item.scenario_name}
                  value={item.scenario_id}
                  sx={{
                    textTransform: 'none',
                    fontWeight: selectedScenarioId === item.scenario_id ? 'bold' : 'normal',
                    color: selectedScenarioId === item.scenario_id ? '#1976d2' : 'inherit'
                  }}
                />
              ))}
            </Tabs>
          </Paper>
          <TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="data table">
              <TableHead
                sx={{
                  background: '#CFE9FD',
                  border: '1px solid rgba(224, 224, 224, 1)'
                }}
              >
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Scenario</TableCell> {/* New Column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell align="left">{new Date(row.fileDate).toLocaleDateString()}</TableCell>
                    <TableCell align="left">{row.score}</TableCell>
                    <TableCell align="left">{row?.scenario?.scenario_name}</TableCell> {/* Display Scenario Name */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default ConversationEvaluation;
