import React, { useEffect, useState } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Modal,
	Typography,
	IconButton,
	Switch,
	FormControlLabel,
	TablePagination,
	CircularProgress,
	TextField
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../../Helper/GetToken';
import { IScenario } from '../../../../types/scenario'; // Adjust the path based on your project structure
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { fireStorage } from '../../../../config/firebase';
import CancelIcon from '@mui/icons-material/Cancel';

interface IPrompt {
	id?: number;
	scenario: IScenario;
	prep_prompt: string | null;
	scoring_prompt: string | null;
	system_prompt: string | null;
	debrief_prompt: string | null;
	meetingAnalytic_prompt: string | null;
	simulationSummary_prompt: string | null;
	data: string | null;
	model: string | null;
	direct_model: boolean;
	realtime_prompt: string;
	videos: videoType[];
}

interface videoType {
	title: string;
	file: any;
}

const PromptTable = () => {
	const [promptData, setPromptData] = useState<IPrompt[]>([]); // Use IPrompt[] as the type for promptData
	const [open, setOpen] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isDisabledDelete, setIsDisabledDelete] = useState(false);
	const [currentPrompt, setCurrentPrompt] = useState<IPrompt | null>(null); // Use IPrompt | null for currentPrompt
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || ''; // Ensure to handle undefined case
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const [search, setSearch] = useState('');
	const [isSearched, setIsSearched] = useState(false);
	const [isDataValid, setIsDataValid] = useState(true);

	useEffect(() => {
		getAllData(search);
	}, [page, limit]);

	const getAllData = async (search?: string) => {
		try {
			setLoading(true);
			const res = await axios.get(`${baseUrl}/api/scenario/scenario-prompts`, {
				headers: {
					Authorization: token || ''
				},
				params: { page, limit, pagination: true, search }
			});
			// console.log(res);

			setPromptData(res.data.data); // Assuming response has data field
			setTotal(res.data.pagination.total);
		} catch (error) {
			console.error(error);
			unhandleToken(error);
		} finally {
			setLoading(false);
		}
	};

	const handleOpen = (prompt: IPrompt) => {
		setCurrentPrompt(prompt);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentPrompt(null);
		setIsDisabled(true);
	};
	const isValidJSON = (data: string) => {
		if (!data) return false;
		try {
			JSON.parse(data);
			return true;
		} catch (error) {
			return false;
		}
	};

	const editSubmit = async () => {
		try {
			const data = currentPrompt?.data;

			if (!data || !isValidJSON(data)) {
				setIsDataValid(false);
				return;
			}

			setIsDataValid(true);
			setIsDisabled(true);

			if (currentPrompt?.videos && currentPrompt?.videos?.length > 0) {
				const uploadPromises = currentPrompt?.videos
					?.filter(item => item?.file !== null)
					.map(async video => {
						if (typeof video.file !== 'string') {
							const fileName = `${new Date().getTime()}-${video?.title}`;
							const storageRef = ref(fireStorage, `scenario_videos_for_prepare/${fileName}`);
							const snapshot = await uploadBytes(storageRef, video.file);
							return { title: video.title, file: await getDownloadURL(snapshot.ref) };
						}
						return video;
					});

				const videos = await Promise.all(uploadPromises);

				currentPrompt.videos = videos;
			}

			const response = await axios.put(`${baseUrl}/api/scenario/edit-prompts`, currentPrompt, {
				headers: {
					Authorization: token
				}
			});
			if (response.data.success) {
				setCurrentPrompt(null);
				setOpen(false);
				getAllData();
			}
		} catch (error) {
			setIsDisabled(false);
			console.log(error);
			unhandleToken(error);
		}
	};
	const deleteHandler = async (prompt: IPrompt) => {
		setIsDisabledDelete(true);
		try {
			const response = await axios.delete(`${baseUrl}/api/scenario/delete-prompts/${prompt?.id}`, {
				headers: {
					Authorization: token
				}
			});
			if (response.data.success) {
				getAllData();
			}
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		} finally {
			setIsDisabledDelete(false);
		}
	};
	const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentPrompt(prevData => {
			if (prevData) {
				// Ensure you return an object that matches IPrompt
				return {
					...prevData,
					direct_model: event.target.checked
				};
			}
			return prevData; // Return null if prevData is null
		});
	};

	const regenerateRealtimePromptHandler = async (currentPrompt: IPrompt) => {
		if (currentPrompt && currentPrompt.data) {
			const response = await axios.post(
				`${baseUrl}/api/scenario/generateRealtimePrompt/${currentPrompt.scenario.scenario_id}`,
				{ data: JSON.parse(currentPrompt.data) },
				{
					headers: {
						Authorization: token
					}
				}
			);
			if (response.data.success) {
				setCurrentPrompt(null);
				setOpen(false);
				getAllData();
			}
		}
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};
	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
	};

	const searchHandler = async () => {
		if (!search) {
			return null;
		}
		if (page !== 1) {
			setPage(1); // Reset page to 1
		}
		getAllData(search); // Fetch data after ensuring the page is reset
		setIsSearched(true);
	};

	const clearHandler = async () => {
		setSearch('');
		setIsSearched(false);

		if (page !== 1) {
			setPage(1); // Reset page to 1
		}
		getAllData(); // Fetch all data after ensuring the page is reset
	};

	return (
		<Box>
			{loading ? (
				<CircularProgress />
			) : (
				<Box>
					<Box
						display="flex"
						alignItems="center"
						gap={2}
						sx={{
							backgroundColor: 'background.paper',
							padding: 2,
							borderRadius: 1,
							boxShadow: 1,
							flexWrap: 'wrap' // Ensures responsiveness on smaller screens
						}}
					>
						<TextField
							name="search"
							placeholder="Search"
							value={search}
							onChange={inputHandler}
							size="small"
							variant="outlined"
							sx={{
								flex: '1 1 auto', // Allows flexibility
								maxWidth: '300px', // Reduced width
								width: '100%', // Fills available space
								borderRadius: '8px'
							}}
						/>
						<Button
							onClick={isSearched ? clearHandler : searchHandler}
							variant="contained"
							color="primary"
							sx={{
								textTransform: 'none',
								borderRadius: '8px',
								flexShrink: 0 // Prevents shrinking on small screens
							}}
						>
							{isSearched ? 'Clear' : 'Submit'}
						</Button>
					</Box>
					<TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>SNo</TableCell>
									<TableCell>Scenario Name</TableCell>
									<TableCell>Prep Prompt</TableCell>
									<TableCell>Scoring Prompt</TableCell>
									<TableCell>System Prompt</TableCell>
									<TableCell>Debrief Prompt</TableCell>
									<TableCell>Meeting Analytics Prompt</TableCell>
									<TableCell>Realtime Prompt</TableCell>
									<TableCell>Data</TableCell>
									<TableCell>Direct Model</TableCell>
									<TableCell>Model Name</TableCell>
									<TableCell>Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{promptData.map((prompt, index) => (
									<TableRow key={index}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>{prompt.scenario.scenario_name}</TableCell>
										<TableCell>
											{prompt.prep_prompt ? (
												prompt.prep_prompt.length > 50 ? (
													<>
														{prompt.prep_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.prep_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.scoring_prompt ? (
												prompt.scoring_prompt.length > 50 ? (
													<>
														{prompt.scoring_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.scoring_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.system_prompt ? (
												prompt.system_prompt.length > 50 ? (
													<>
														{prompt.system_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.system_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.simulationSummary_prompt ? (
												prompt.simulationSummary_prompt.length > 50 ? (
													<>
														{prompt.simulationSummary_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.simulationSummary_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.meetingAnalytic_prompt ? (
												prompt.meetingAnalytic_prompt.length > 50 ? (
													<>
														{prompt.meetingAnalytic_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.meetingAnalytic_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.realtime_prompt ? (
												prompt.realtime_prompt.length > 50 ? (
													<>
														{prompt.realtime_prompt.substring(0, 50)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.meetingAnalytic_prompt
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											{prompt.data ? (
												prompt.data.length > 30 ? (
													<>
														{prompt.data.substring(0, 30)}...
														<Button onClick={() => handleOpen(prompt)}>Read More</Button>
													</>
												) : (
													prompt.data
												)
											) : (
												'Null'
											)}
										</TableCell>
										<TableCell>
											<Switch checked={prompt.direct_model} disabled name="direct_model" color="primary" />
										</TableCell>
										<TableCell>{prompt.model ? prompt.model : 'Null'}</TableCell>
										<TableCell>
											<IconButton disabled={isDisabledDelete} onClick={() => deleteHandler(prompt)}>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={total}
							page={page - 1}
							onPageChange={handleChangePage}
							rowsPerPage={limit}
							onRowsPerPageChange={handleChangeRowsPerPage}
							rowsPerPageOptions={[5, 10, 20]}
						/>
					</TableContainer>
				</Box>
			)}

			<Modal open={open}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						maxHeight: '90%',
						overflowY: 'auto',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4
					}}
				>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
							<CloseIcon />
						</IconButton>
					</Box>
					{currentPrompt && (
						<>
							<Typography variant="h6" component="h2">
								{currentPrompt.scenario.scenario_name}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between'
								}}
							>
								<Box>
									{isDisabled ? (
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<EditIcon />}
											onClick={() => setIsDisabled(false)}
										>
											Edit Mode
										</Button>
									) : (
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<EditIcon />}
											onClick={() => setIsDisabled(true)}
										>
											Read Mode
										</Button>
									)}
								</Box>
								<Box>
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										startIcon={<RestartAltIcon />}
										onClick={() => regenerateRealtimePromptHandler(currentPrompt)}
									>
										Regenerate realtime prompt
									</Button>
								</Box>
							</Box>
							<Box sx={{ mt: 2 }}>
								<strong>Prep Prompt:</strong>{' '}
								<textarea
									className="promptTextArea"
									value={currentPrompt.prep_prompt || ''}
									name="prep_prompt"
									id="prep_prompt"
									title="Enter Prepare Prompt"
									placeholder="Enter Prepare Prompt"
									disabled={isDisabled}
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
							</Box>
							<Typography sx={{ mt: 2 }}>
								<strong>Scoring Prompt:</strong>
								<textarea
									className="promptTextArea"
									name="scoring_prompt"
									id="scoring_prompt"
									placeholder="Enter Scoring Prompt"
									disabled={isDisabled}
									value={currentPrompt.scoring_prompt || ''}
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
									title="Enter Scoring Prompt"
								/>
							</Typography>
							<Typography sx={{ mt: 2 }}>
								<strong>System Prompt:</strong>
								<textarea
									className="promptTextArea"
									name="system_prompt"
									id="system_prompt"
									title="Enter System Prompt"
									disabled={isDisabled}
									placeholder="Enter System Prompt"
									value={currentPrompt.system_prompt || ''}
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
							</Typography>
							<Typography sx={{ mt: 2 }}>
								<strong>Debrief Prompt:</strong>
								<textarea
									className="promptTextArea"
									name="simulationSummary_prompt"
									id="simulationSummary_prompt"
									title="Enter Debrief Prompt"
									disabled={isDisabled}
									value={currentPrompt.simulationSummary_prompt || ''}
									placeholder="Enter Debrief Prompt"
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
							</Typography>
							<Typography sx={{ mt: 2 }}>
								<strong>Meeting Analytics Prompt:</strong>
								<textarea
									className="promptTextArea"
									name="meetingAnalytic_prompt"
									id="meetingAnalytic_prompt"
									title="Enter Analytic meeting Prompt"
									disabled={isDisabled}
									value={currentPrompt.meetingAnalytic_prompt || ''}
									placeholder="Enter Analytic meeting Prompt"
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
							</Typography>
							<Typography sx={{ mt: 2 }}>
								<strong>Realtime Prompt:</strong>
								<textarea
									className="promptTextArea"
									name="realtime_prompt"
									id="realtime_prompt"
									title="Enter Prompt For Realtime Interaction"
									disabled={isDisabled}
									placeholder="Enter Prompt For Realtime Interaction"
									value={currentPrompt.realtime_prompt || ''}
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
							</Typography>
							<Typography sx={{ mt: 2 }}>
								<strong>Data:</strong>
								<textarea
									style={{ borderColor: !isDataValid ? 'red' : '' }}
									className="promptTextArea"
									name="data"
									id="data"
									title="Enter Data"
									disabled={isDisabled}
									placeholder="Enter Data"
									value={currentPrompt.data || ''}
									onChange={e => {
										setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
									}}
								/>
								{!isDataValid && <strong style={{ color: 'red' }}>Invalid JSON</strong>}
							</Typography>
							<Typography sx={{ mt: 2 }}>
								{/* <strong>Direct Model:</strong> */}
								<FormControlLabel
									control={
										<Switch
											checked={currentPrompt.direct_model}
											onChange={handleToggleChange}
											name="direct_model"
											color="primary"
											disabled={isDisabled}
										/>
									}
									label="Direct Model"
								/>
							</Typography>
							<Box>
								{currentPrompt?.videos?.length > 0 &&
									currentPrompt?.videos?.map((video: any, index: any) => (
										<div style={{ display: 'flex', gap: '8px', marginTop: '8px' }} key={index}>
											<input
												style={{ width: '30%', height: '40px' }}
												type="text"
												name={`videoTitle${index}`}
												id={`videoTitle${index}`}
												className="formInput"
												placeholder="Video Title"
												value={video.title}
												onChange={(e: any) =>
													setCurrentPrompt({
														...currentPrompt,
														videos: currentPrompt?.videos.map((v: any, i: any) =>
															i === index ? { ...v, title: e.target.value } : v
														)
													})
												}
												disabled={isDisabled}
											/>

											{video?.file && (
												<video
													controls
													style={{ width: '30%' }}
													src={typeof video?.file === 'string' ? video?.file : URL.createObjectURL(video?.file)}
												>
													Your browser does not support to load the video.
												</video>
											)}
											<div style={{ width: '30%' }}>
												<input
													placeholder="Add or replace video"
													type="file"
													name={`videoFile${index}`}
													id={`videoFile${index}`}
													accept="video/*"
													onChange={(e: any) =>
														setCurrentPrompt({
															...currentPrompt,
															videos: currentPrompt?.videos.map((v: any, i: any) =>
																i === index ? { ...v, file: e.target.files[0] } : v
															)
														})
													}
													disabled={isDisabled}
												/>
												{video?.file && <p>To replace upload another video</p>}
											</div>
											<div style={{ display: 'flex', justifyContent: 'end', width: '10%' }}>
												<CancelIcon
													onClick={() => {
														if (isDisabled) return;
														setCurrentPrompt({
															...currentPrompt,
															videos: currentPrompt?.videos.filter((_, i) => i !== index)
														});
													}}
													sx={{ color: isDisabled ? 'gray' : 'red', cursor: isDisabled ? 'not-allowed' : 'pointer' }}
												/>
											</div>
										</div>
									))}
								<Button
									disabled={isDisabled}
									sx={{ mt: 1 }}
									variant="contained"
									color="primary"
									onClick={() => {
										if (currentPrompt?.videos?.length > 0) {
											setCurrentPrompt({
												...currentPrompt,
												videos: currentPrompt?.videos?.concat({ title: '', file: null })
											});
										} else {
											setCurrentPrompt({
												...currentPrompt,
												videos: [{ title: '', file: null }]
											});
										}
									}}
								>
									Add Video
								</Button>
							</Box>
							<Box sx={{ mt: 2 }}>
								<strong>Model Name:</strong>
								<div className="input-container">
									<input
										type="text"
										name="model"
										id="model"
										className="formInput"
										placeholder="Model Name"
										maxLength={200}
										disabled={isDisabled}
										onChange={e => {
											setCurrentPrompt({ ...currentPrompt, [e.target.name]: e.target.value });
										}}
										value={currentPrompt.model || ''}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
											}
										}}
										pattern="^[a-zA-Z1-9].*"
										title="Please enter a value starting with an alphanumeric character without leading whitespace"
										required={currentPrompt.direct_model}
									/>
									<p className="char-count">{currentPrompt.model?.length || 0}/200</p>
								</div>
							</Box>
						</>
					)}
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
						<Button
							type="button"
							disabled={isDisabled}
							variant="contained"
							size="large"
							sx={{ padding: '5px 20px' }}
							onClick={editSubmit}
						>
							Update
						</Button>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default PromptTable;
