import {
	Box,
	Button,
	CircularProgress,
	Modal,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import AddCohort from './AddCohort';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EditCohort from './EditCohort';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditCohortTemplate from './EditCohortTemplate';
import CohortSummary from './CohortSummary';
const CohortTable = () => {
	const [allCohort, setAllCohort] = useState([]);
	const [isRefresh, setIsRefresh] = useState(false);
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [summaryOpen, setSummaryOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [templateOpen, setTemplateOpen] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [selectedCohort, setSelectedCohort] = useState();
	const [loading, setLoading] = useState(false);
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getAllCohort();
	}, [isRefresh, page, limit]);

	const getAllCohort = async () => {
		setLoading(true);
		await axios
			.get(`${baseUrl}/api/admin/getAllCohorts`, {
				headers: {
					Authorization: token
				},
				params: { page, limit, pagination: true }
			})
			.then(res => {
				setAllCohort(res.data.cohorts);
				setTotal(res.data.pagination.total);
				setLoading(false);
			})
			.catch(e => {
				setLoading(false);
				console.log(e);
				unhandleToken(e);
			});
	};

	const deleteHandler = async (id: string) => {
		try {
			await axios
				.delete(`${baseUrl}/api/admin/deleteCohort/${id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					console.log(res);
					if (res.data.success) {
						setIsRefresh(!isRefresh);
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};
	const editHandler = (data: any) => {
		setSelectedCohort(data);
		setEditOpen(true);
	};

	const summaryHandler = (data: any) => {
		setSelectedCohort(data);
		setSummaryOpen(true);
	};

	const editTemplate = (data: any) => {
		setSelectedCohort(data);
		setTemplateOpen(true);
	};

	const editEmailFeatureByCohort = async (data: any, check: boolean) => {
		try {
			setLoading(true);
			await axios
				.put(
					`${baseUrl}/api/admin/edit-email-feature/${data.id}`,
					{
						enabledEmailFeature: check,
						emailTemplate: data?.emailTemplate,
						emailSubject: data?.emailSubject
					},
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(() => {
					setLoading(false);
					setIsRefresh(!isRefresh);
				})
				.catch(e => {
					setLoading(false);
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};
	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Box>
						<Typography variant="h6">Cohort</Typography>
					</Box>
					<Box>
						<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
							Add
						</Button>
					</Box>
				</Box>
				<Box>
					{loading ? (
						<CircularProgress />
					) : (
						<TableContainer sx={{ borderRadius: '8px' }}>
							<Table>
								<TableHead
									sx={{
										background: '#CFE9FD',
										border: '1px solid rgba(224, 224, 224, 1)'
									}}>
									<TableRow sx={{ textWrap: 'nowrap' }}>
										<TableCell align="left">Name</TableCell>
										<TableCell align="left">Scenario Category</TableCell>
										<TableCell align="left">Email Feature</TableCell>
										<TableCell align="left">Edit</TableCell>
										<TableCell align="left">Delete</TableCell>
										<TableCell align="left">Summary</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(allCohort) &&
										allCohort.length > 0 &&
										allCohort.map(
											(
												items: {
													name: string;
													id: string;
													scenario: { scenario_name: string }[];
													enabledEmailFeature: boolean;
												},
												index: number
											) => {
												return (
													<>
														<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
															<TableCell align="left">{items.name}</TableCell>
															<TableCell
																align="left"
																// onClick={() => {
																// 	setSelectedCohortId(items.cohort.id);
																// 	setSingleCohortOpen(true);
																// }}
															>
																<Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
																	{Array.isArray(items?.scenario) && items.scenario.length > 0
																		? items.scenario.map((itemd: { scenario_name: string }, indexx: number) => {
																				return (
																					<Box
																						key={indexx}
																						sx={{
																							border: '1pt solid grey',
																							padding: '5px 7px',
																							color: '#656565',
																							backgroundColor: '#F9FBFF'
																						}}>
																						{itemd.scenario_name}
																					</Box>
																				);
																			})
																		: 'No Category Assigned'}
																</Box>
															</TableCell>
															<TableCell align="left">
																<Switch
																	disabled={loading}
																	checked={items?.enabledEmailFeature}
																	onChange={e => {
																		editEmailFeatureByCohort(items, e.target.checked);
																	}}
																/>
																<EditNoteIcon
																	color="primary"
																	onClick={() => {
																		if (!loading) editTemplate(items);
																	}}
																	style={{ cursor: 'pointer' }}
																/>
															</TableCell>
															<TableCell align="left">
																<Button
																	variant="outlined"
																	color="secondary"
																	size="small"
																	startIcon={<EditIcon />}
																	onClick={() => editHandler(items)}>
																	Edit
																</Button>
															</TableCell>
															<TableCell align="left">
																<Button
																	variant="outlined"
																	size="small"
																	startIcon={<DeleteIcon />}
																	onClick={() => deleteHandler(items.id)}>
																	Delete
																</Button>
															</TableCell>
															<TableCell align="left">
																<Button
																	variant="outlined"
																	size="small"
																	startIcon={<SummarizeIcon />}
																	onClick={() => summaryHandler(items)}>
																	Summary
																</Button>
															</TableCell>
														</TableRow>
													</>
												);
											}
										)}
								</TableBody>
							</Table>
							<TablePagination
								component="div"
								count={total}
								page={page - 1}
								onPageChange={handleChangePage}
								rowsPerPage={limit}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPageOptions={[5, 10, 20]}
							/>
						</TableContainer>
					)}
				</Box>
			</Box>
			<Modal open={templateOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<EditCohortTemplate
					setTemplateOpen={setTemplateOpen}
					selectedCohort={selectedCohort}
					editEmailFeatureByCohort={editEmailFeatureByCohort}
				/>
			</Modal>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<AddCohort setOpen={setOpen} setIsRefresh={setIsRefresh} isRefresh={isRefresh} />
			</Modal>
			<Modal open={editOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<EditCohort
					setEditOpen={setEditOpen}
					setIsRefresh={setIsRefresh}
					selectedCohort={selectedCohort}
					isRefresh={isRefresh}
				/>
			</Modal>
			<Modal open={summaryOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<CohortSummary setSummaryOpen={setSummaryOpen} selectedCohort={selectedCohort} />
			</Modal>
		</>
	);
};

export default CohortTable;
