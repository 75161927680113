import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const UserInstructions = () => {
	return (
		<Box sx={{ backgroundColor: '#ffffff', padding: '36pt', textAlign: 'center' }}>
			<Box>
				<img src="images/UserInstructions/image3.png" alt="Image 3" style={{ width: '190.12px', height: '196.52px' }} />
			</Box>
			<Box>
				<img src="images/UserInstructions/image1.png" alt="Image 1" style={{ width: '283px', height: '70.99px' }} />
			</Box>

			<Typography variant="h1" sx={{ marginTop: '12pt', marginBottom: '8pt', fontSize: '32pt', color: '#0f4761' }}>
				USER INSTRUCTIONS
			</Typography>
			<Typography variant="subtitle1" sx={{ color: '#595959', fontFamily: 'Aptos' }}>
				AUGUST 2024
			</Typography>

			<Typography variant="h2" sx={{ marginY: '12pt', fontSize: '20pt', color: '#0f4761', textAlign: 'left' }}>
				WHAT IS SIMSOLA.AI
			</Typography>
			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				Simsola AI is a platform designed to enhance your leadership skills. It provides a safe environment where you
				can develop core competencies in areas like Core Communication, Coaching, and more. Through repeated practice
				and feedback, you can refine your abilities until you master each skill at every level. Our goal is to help you
				build &ldquo;muscle memory&rdquo; for leadership skills, so you can apply them instinctively in challenging
				situations.
			</Typography>

			<Link
				style={{
					color: '#0f4761'
				}}
				to="/simulations">
				<Typography
					variant="h2"
					sx={{
						marginY: '12pt',
						marginTop: '30pt',
						fontSize: '20pt',

						color: '#0f4761',
						textAlign: 'left'
					}}>
					SIMULATION
				</Typography>
			</Link>

			<Typography variant="body1" sx={{ fontSize: '14pt', marginBottom: '25pt', color: '#0e0e0e', textAlign: 'left' }}>
				When you first log in as a user, it’s fun to try some of the simulations. Here is how you get started:
			</Typography>

			<Typography variant="h3" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left', marginTop: '8pt' }}>
				<strong>Step 1:</strong>
				<span
					style={{
						marginLeft: '8px',
						fontSize: '14pt',

						color: '#0e0e0e',
						textAlign: 'left'
					}}>
					Click on <strong>Simulation</strong> on the left side of the menu bar.
				</span>
			</Typography>

			<Typography
				variant="h3"
				sx={{
					fontSize: '14pt',

					color: '#0e0e0e',
					textAlign: 'left',
					marginTop: '8pt'
				}}>
				<strong>Step 2:</strong>
				<span
					style={{
						marginLeft: '8px',
						fontSize: '14pt',

						color: '#0e0e0e',
						textAlign: 'left'
					}}>
					Pick a simulation scenario and click on the <strong>Next</strong> button below it.
				</span>
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8pt', marginBottom: '8pt' }}>
				<img
					src="images/UserInstructions/image5.png"
					alt="Simulation Screenshot"
					style={{ border: '1.33px solid #000000', margin: '8pt 0', width: '609.5px', height: '433.69px' }}
				/>
			</Box>

			<Typography
				variant="h3"
				sx={{
					fontSize: '14pt',

					color: '#0e0e0e',
					textAlign: 'left',
					marginTop: '8pt'
				}}>
				<strong>Step 3:</strong>
				<span
					style={{
						marginLeft: '8px',
						fontSize: '14pt',

						color: '#0e0e0e',
						textAlign: 'left'
					}}>
					You can have a preparation session with a coach regarding the simulation. At any point, you can select{' '}
					<strong>Next</strong> to move to the simulation session.
				</span>
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8pt', marginBottom: '8pt' }}>
				<img
					src="images/UserInstructions/image2.png"
					alt="Simulation Preparation"
					style={{ border: '1.33px solid #000000', margin: '8pt 0', width: '624px', height: '433.33px' }}
				/>
			</Box>

			<Typography
				variant="h3"
				sx={{
					fontSize: '14pt',

					color: '#0e0e0e',
					textAlign: 'left',
					marginTop: '8pt'
				}}>
				<strong>Step 4:</strong>
				<span
					style={{
						marginLeft: '8px',
						fontSize: '14pt',

						color: '#0e0e0e',
						textAlign: 'left'
					}}>
					Once you click on <strong>Next</strong>, you’ll start the simulation itself, try to have a productive
					conversation in the scenario to achieve your objective. If you feel stuck, you can click <strong>Next</strong>{' '}
					in the top right corner.
				</span>
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8pt', marginBottom: '8pt' }}>
				<img
					src="images/UserInstructions/image7.png"
					alt="Simulation Interface"
					style={{ border: '1.33px solid #000000', margin: '8pt 0', width: '624px', height: '374.6px' }}
				/>
			</Box>

			<Typography
				variant="h3"
				sx={{
					fontSize: '14pt',

					color: '#0e0e0e',
					textAlign: 'left',
					marginTop: '8pt'
				}}>
				<strong>Step 5:</strong>
				<span
					style={{
						marginLeft: '8px',
						fontSize: '14pt',

						color: '#0e0e0e',
						textAlign: 'left'
					}}>
					<strong>A debrief report</strong> will be generated for you, providing an analysis of the strengths and
					weaknesses in your communication style based on customized rubrics. To understand your score, click on the
					colored bar beneath each rubric name, where you’ll find the reasoning behind it. For definitions and examples
					of how to apply each rubric in conversations, click the question mark in the upper right corner of the rubric
					name.
				</span>
			</Typography>

			<Typography
				variant="body1"
				sx={{
					fontSize: '14pt',

					color: '#0e0e0e',
					textAlign: 'left',
					marginTop: '12pt',
					marginBottom: '12pt'
				}}>
				The recommendations offer practical suggestions to help you improve your communication skills, guiding you on
				how to approach specific parts of the conversation differently next time.
			</Typography>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				If you want to do another session to try something new or apply the techniques suggested by the debrief report,
				you can click the <strong>Back</strong> button to try the simulation again.
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8pt', marginBottom: '8pt' }}>
				<img
					src="images/UserInstructions/image6.png"
					alt="Simulation Interface"
					style={{ border: '1.33px solid #000000', margin: '8pt 0', width: '624px', height: '374.6px' }}
				/>
			</Box>

			<Typography
				variant="h2"
				sx={{
					marginY: '12pt',
					marginTop: '30pt',
					fontSize: '20pt',

					color: '#0f4761',
					textAlign: 'left'
				}}>
				VOICE INTERACTION
			</Typography>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				At any point, you can choose to use voice interaction instead of writing. To do so, you can click on the
				Microphone icon (1). Then click on the new Microphone icon at the center of the prompt area (2)
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8pt', marginBottom: '8pt' }}>
				<img
					src="images/UserInstructions/image4.png"
					alt="Simulation Interface"
					style={{ border: '1.33px solid #000000', margin: '8pt 0', width: '624px', height: '374.6px' }}
				/>
			</Box>

			<Link
				style={{
					color: '#0f4761'
				}}
				to="/progress">
				<Typography
					variant="h2"
					sx={{
						marginY: '12pt',
						marginTop: '30pt',
						fontSize: '20pt',

						color: '#0f4761',
						textAlign: 'left'
					}}>
					PROGRESS
				</Typography>
			</Link>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				This section shows a history of all the debriefing sessions. You can filter by level to look at scores and can
				click on each debrief to review the details.
			</Typography>

			<Link
				style={{
					color: '#0f4761'
				}}
				to="/session">
				<Typography
					variant="h2"
					sx={{
						marginY: '12pt',
						marginTop: '30pt',
						fontSize: '20pt',

						color: '#0f4761',
						textAlign: 'left'
					}}>
					COACH
				</Typography>
			</Link>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				In this section you can interact with the coach beyond the specific scenarios. You can always go to this section
				to ask the coach for general advice on areas you need help with.
			</Typography>

			<Link
				style={{
					color: '#0f4761'
				}}
				to="/analyze-meetings">
				<Typography
					variant="h2"
					sx={{
						marginY: '12pt',
						marginTop: '30pt',
						fontSize: '20pt',

						color: '#0f4761',
						textAlign: 'left'
					}}>
					ANALYZE MEETINGS
				</Typography>
			</Link>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				In this section, you can upload an audio or video recording, or a transcript of a meeting. The meeting will be
				analyzed to assess each attendee&apos;s communication skills using the rubrics you&apos;re learning. This will
				help you understand and reflect on your performance in real-life situations.
			</Typography>

			<Link
				style={{
					color: '#0f4761'
				}}
				to="/community">
				<Typography
					variant="h2"
					sx={{
						marginY: '12pt',
						marginTop: '30pt',
						fontSize: '20pt',
						color: '#0f4761',
						textAlign: 'left'
					}}>
					COMMUNITY & RESOURCE
				</Typography>
			</Link>

			<Typography variant="body1" sx={{ fontSize: '14pt', color: '#0e0e0e', textAlign: 'left' }}>
				In this section, you can find learning materials published by coach.
			</Typography>
		</Box>
	);
};

export default UserInstructions;
