import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Modal,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import './Company.css';
import { AddCompany } from './AddCompany';
import { CompanyType, EditCompany } from './EditCompany';
export const CompanyTable = () => {
	const [allCompany, setAllCompany] = useState([]);
	const [isRefresh, setIsRefresh] = useState(false);
	const [open, setOpen] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);
	const [message, setMessage] = useState('');
	const [editOpen, setEditOpen] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [selectedCompany, setSelectedCompany] = useState<CompanyType>();
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const vertical = 'top';
	const horizontal = 'right';
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getAllCompany();
	}, [isRefresh, limit, page]);
	const getAllCompany = async () => {
		setLoading(true);
		await axios
			.get(`${baseUrl}/getAllCompanies`, {
				params: { page, limit, pagination: true },
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setAllCompany(res.data.companies);
					setTotal(res.data.pagination.total);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editHandler = async (data: CompanyType) => {
		setSelectedCompany(data);
		setEditOpen(true);
	};
	const deleteHandler = async (id: number | undefined) => {
		try {
			await axios
				.delete(`${baseUrl}/company/${id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data.success) {
						setIsRefresh(!isRefresh);
					} else {
						setMessage(res.data.message);
						setOpenSnack(true);
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};
	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Box>
						<Typography variant="h6">Company</Typography>
					</Box>
					<Box>
						<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
							Add
						</Button>
					</Box>
				</Box>
				<Box>
					{loading ? (
						<CircularProgress />
					) : (
						<TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
							<Table>
								<TableHead
									sx={{
										background: '#CFE9FD',
										border: '1px solid rgba(224, 224, 224, 1)'
									}}>
									<TableRow sx={{ textWrap: 'nowrap' }}>
										<TableCell align="left">Company Name</TableCell>
										<TableCell align="left">Contact Person Name</TableCell>
										<TableCell align="left">Contact Person Email</TableCell>
										<TableCell align="left">Edit</TableCell>
										<TableCell align="left">Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(allCompany) &&
										allCompany.length > 0 &&
										allCompany.map((items: CompanyType, index: number) => {
											return (
												<>
													<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
														<TableCell align="left">{items.name}</TableCell>
														<TableCell align="left">
															{items?.contact
																? `${items.contact.first_name || ''} ${items.contact.last_name || ''}`.trim()
																: ''}
														</TableCell>
														<TableCell align="left">{items?.contact?.email}</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																color="secondary"
																size="small"
																startIcon={<EditIcon />}
																onClick={() => editHandler(items)}>
																Edit
															</Button>
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																size="small"
																startIcon={<DeleteIcon />}
																onClick={() => deleteHandler(items?.id)}>
																Delete
															</Button>
														</TableCell>
													</TableRow>
												</>
											);
										})}
								</TableBody>
							</Table>
							<TablePagination
								component="div"
								count={total}
								page={page - 1}
								onPageChange={handleChangePage}
								rowsPerPage={limit}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPageOptions={[5, 10, 20]}
							/>
						</TableContainer>
					)}
				</Box>
			</Box>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<AddCompany setOpen={setOpen} setIsRefresh={setIsRefresh} isRefresh={isRefresh} />
			</Modal>
			<Modal open={editOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<EditCompany
					setEditOpen={setEditOpen}
					setIsRefresh={setIsRefresh}
					selectedCompany={selectedCompany}
					isRefresh={isRefresh}
				/>
			</Modal>
			<Snackbar
				open={openSnack}
				autoHideDuration={5000}
				onClose={() => setOpenSnack(!openSnack)}
				sx={{ padding: '10px 5px' }}
				anchorOrigin={{ vertical, horizontal }}>
				<Alert
					onClose={() => setOpenSnack(!openSnack)}
					severity="error"
					variant="outlined"
					sx={{
						width: '100%',
						padding: '10px 5px',
						border: '1px solid rgba(5, 10, 58, 0.3)',
						borderRadius: '5px',
						background: 'white'
					}}>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};
